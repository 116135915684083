import React, { useRef, useContext ,useState,useEffect} from 'react'
import { useFormContext ,useWatch} from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'
import Box from '@material-ui/core/Box'
import TaciOcnA6aShipmentsFormProperties from './TaciOcnA6aShipmentsFormProperties'


const {
    button: { CngPrimaryButton },
    form: { CngForm },
    CngDialog
} = components

const { FormState } = constants

function TaciOcnA6aShipmentsDialog(props) {
    const { open, onClose, shipment, showNotification } = props
    const { getValues, setValue, formState: { isSubmitting } } = useFormContext()
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const lastIndex = useRef(0)

    const { initialValues, makeValidationSchema } = TaciOcnA6aShipmentsFormProperties.formikProps
    const [count, setCount] = useState([]);

    const taciOcnA6aShipments = useWatch({ name: "taciOcnA6aShipments" })
    const taciOcnA6aContainers = useWatch({ name: 'taciOcnA6aContainers' })

    function handleAddShipment(data) {
        const shipments = [...getValues('taciOcnA6aShipments'), data].map(
            (shipment) => ({ ...shipment, _id: ++lastIndex.current })
        )

        setValue('taciOcnA6aShipments', shipments)
        onClose()
    }

    useEffect(() => {
        var count=1;
        if (taciOcnA6aShipments) {
            (taciOcnA6aShipments).forEach((item) => {
                    count= count+1;
            })
        }
        setCount(count);
    }, [taciOcnA6aShipments]);

    function handleEditShipment(data) {
        const shipments = [...getValues('taciOcnA6aShipments')]
        const index = shipments.findIndex((shipment) =>
            shipment.id ? shipment.id === data.id : shipment._id === data._id
        )

        shipments[index] = data

        setValue('taciOcnA6aShipments', shipments)
        onClose()
    }

    return (
        <CngDialog
            dialogContent={
                <CngForm
                    fieldLevel='form'
                    formikProps={{
                        initialValues: shipment || initialValues,
                        makeValidationSchema: makeValidationSchema,
                        onSubmit: (data) => {
                            if (shipment) {
                                handleEditShipment(data)
                            } else {
                                handleAddShipment(data)
                            }
                        }
                    }}
                    formState={FormState.COMPLETED}
                    innerForm={true}
                    renderBodySection={(labelMap, shouldHideMap) => (
                        <TaciOcnA6aShipmentsFormProperties.Fields showNotification={showNotification} shouldHideMap={shouldHideMap} count={count} container={taciOcnA6aContainers} />
                    )}
                    renderButtonSection={() => (
                        <Box display='flex' justifyContent='flex-end'>
                            <CngPrimaryButton type='submit' disabled={isSubmitting}>
                                {shipment ? "Update" : "Save"}
                            </CngPrimaryButton>
                        </Box>
                    )}
                />
            }
            dialogTitle={getTranslatedText('A6A', 'taciOcnA6aShipments')}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            fullWidth
            maxWidth='lg'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default TaciOcnA6aShipmentsDialog

import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6aContainersDialog from '../TaciOcnA6aContainersDialog'
import TaciOcnA6aContainersTable from '../TaciOcnA6aContainersTable'

function A6AContainersSection(props) {
    const { showNotification } = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const [dialog, setDialog] = useState({ open: false, container: null })

    const taciOcnA6aContainers = useWatch({ name: 'taciOcnA6aContainers' })
    const docFormat = useWatch({ name: 'docFormat' })


    function handleRemoveContainer(data) {
        const taciOcnA6aContainers = [...getValues('taciOcnA6aContainers')].filter((container) =>
            container.id ? container.id !== data.id : container._id !== data._id
        )

        setValue('taciOcnA6aContainers', taciOcnA6aContainers, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('A6A', 'taciOcnA6aContainers') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${taciOcnA6aContainers.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <TaciOcnA6aContainersTable
                    data={taciOcnA6aContainers}
                    onAddContainer={() => setDialog({ open: true, container: null })}
                    onEditContainer={(container) => setDialog({ open: true, container })}
                    onDeleteContainer={handleRemoveContainer}
                />

                <TaciOcnA6aContainersDialog
                    onClose={() => setDialog({ open: false, container: null })}
                    open={dialog.open}
                    container={dialog.container}
                    docFormat={docFormat}
                    showNotification={showNotification}
                />
            </CngSection>
        </Grid>
    )
}

export default A6AContainersSection
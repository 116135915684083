import React, { useContext, useEffect, useState } from 'react'
import { useFormContext, useWatch } from "react-hook-form";
import { components, constants } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import makeValidationSchema from './TaciOcnA6aShipmentsMakeValidationSchema'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'
import { NaDangerousGoodsAutocompleteField, NaHSCodeAutocompleteField, NaProductMasterAutoCompleteField } from 'src/components/na'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import _ from 'lodash'


const {
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngCheckboxField,
      CngUomAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  saveFlag: false,
  productIdentification: "",
  itemNo: "",
  itemDesc1: "",
  itemDesc2: "",
  itemDesc3: "",
  itemDesc4: "",
  itemDesc5: "",
  itemDesc6: "",
  itemDesc7: "",
  itemDesc8: "",
  itemDesc9: "",
  noOfPkgs: "",
  pkgUom: "",
  grossWeight: "",
  grossweightUom: "",
  volume: "",
  volumeUom: "",
  hsCode: "",
  undgCode: "",
  markNo: "",
  containerNo: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap, count, container }) {
  const { setValue,getValues } = useFormContext();
  const { getTranslatedText } = useContext(A6ATranslationContext)
  const [containerDropDown, setContainerDropDown] = useState()

  const saveFlag = useWatch({ name: "saveFlag" })

  useEffect(() => {
    var itemNoSno=getValues("itemNo")
    if(itemNoSno===null || itemNoSno===''){
      setValue("itemNo",count);
   }
}, []);

  useEffect(() => {
    if (container && container.length > 0) {
      let containerDropDown = [];
      container.forEach((value) => {
        let contDropDownObj = {
          text: value.containerNo,
          value: value.containerNo
        }

        containerDropDown.push(contDropDownObj)
      })

      setContainerDropDown(containerDropDown)
    }
  }, [container]);

  function handleApplyTemplate(template) {
    if (!template) {
      return
    }

    const {
      productDesc1,
      productDesc2,
      productDesc3,
      productDesc4,
      productDesc5,
      productDesc6,
      productDesc7,
      productDesc8,
      productDesc9,
      hsCode
    } = template

    setValue("itemDesc1", productDesc1 || '', { shouldDirty: true })
    setValue("itemDesc2", productDesc2 || '', { shouldDirty: true })
    setValue("itemDesc3", productDesc3 || '', { shouldDirty: true })
    setValue("itemDesc4", productDesc4 || '', { shouldDirty: true })
    setValue("itemDesc5", productDesc5 || '', { shouldDirty: true })
    setValue("itemDesc6", productDesc6 || '', { shouldDirty: true })
    setValue("itemDesc7", productDesc7 || '', { shouldDirty: true })
    setValue("itemDesc8", productDesc8 || '', { shouldDirty: true })
    setValue("itemDesc9", productDesc9 || '', { shouldDirty: true })
    setValue("hsCode", hsCode || '', { shouldDirty: true })
  }


  return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.productDropDown}>
        <NaProductMasterAutoCompleteField
          name="productDropDown"
          label={getTranslatedText('A6A', 'productDropDown')}
          disabled={disabled}
          onChange={(_, options) => { handleApplyTemplate(options?.data) }}
          lookupProps={{
            filters: [{ field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }]
          }}
          size="small"
        />
      </CngGridItem>

      <CngGridItem xs={12} md={2} shouldHide={shouldHideMap.saveFlag}>
        <CngCheckboxField
          label={
            <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {getTranslatedText('A6A', 'saveFlag')}
            </Typography>
          }
          name="saveFlag"
          onChange={(e) => setValue('saveFlag', e.target.checked)}
          size='small'
          style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
        />
      </CngGridItem>

      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productIdentification}>
        <CngTextField
          name="productIdentification"
          inputProps={{ maxLength: 35 }}
          label={getTranslatedText('A6A', 'productIdentification')}
          disabled={disabled || !saveFlag}
          onBlur={(e) => { setValue("productIdentification", e.target.value.trim()) }}
          required
          size="small"
        />
      </CngGridItem>


      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemNo}>
        <CngTextField
          name="itemNo"
          inputProps={{ maxLength: 4 }}
          label={getTranslatedText('A6A', 'itemNo')}
          disabled={true}
          required
          size="small"
        />
      </CngGridItem>

      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc1}>
        <CngTextField
          name="itemDesc1"
          label={getTranslatedText('A6A', 'itemDesc1')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc1", e.target.value.trim()) }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc2}>
        <CngTextField
          name="itemDesc2"
          label={getTranslatedText('A6A', 'itemDesc2')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc2", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc3}>
        <CngTextField
          name="itemDesc3"
          label={getTranslatedText('A6A', 'itemDesc3')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc3", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc4}>
        <CngTextField
          name="itemDesc4"
          label={getTranslatedText('A6A', 'itemDesc4')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc4", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc5}>
        <CngTextField
          name="itemDesc5"
          label={getTranslatedText('A6A', 'itemDesc5')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc5", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc6}>
        <CngTextField
          name="itemDesc6"
          label={getTranslatedText('A6A', 'itemDesc6')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc6", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc7}>
        <CngTextField
          name="itemDesc7"
          label={getTranslatedText('A6A', 'itemDesc7')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc7", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc8}>
        <CngTextField
          name="itemDesc8"
          label={getTranslatedText('A6A', 'itemDesc8')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc8", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDesc9}>
        <CngTextField
          name="itemDesc9"
          label={getTranslatedText('A6A', 'itemDesc9')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("itemDesc9", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.noOfPkgs}>
        <CngTextField
          name="noOfPkgs"
          label={getTranslatedText('A6A', 'noOfPkgs')}
          disabled={disabled}
          inputProps={{ maxLength: 8 }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.pkgUom}>
        <CngUomAutocompleteField
          name="pkgUom"
          label={getTranslatedText('A6A', 'pkgUom')}
          disabled={disabled}
          lookupProps={{
            label: (record) => { return `${record.code};${record.descriptionEn}` },
            filters: [{ field: 'uomType', operator: 'equal', value: 'ACI' }]
          }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWeight}>
        <CngTextField
          name="grossWeight"
          label={getTranslatedText('A6A', 'grossWeight')}
          disabled={disabled}
          inputProps={{ maxLength: 16 }}
          onBlur={(e) => { setValue("grossWeight", e.target.value.trim()) }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossweightUom}>
        <CngCodeMasterAutocompleteField
          name="grossweightUom"
          label={getTranslatedText('A6A', 'grossweightUom')}
          codeType='GROSS_WEIGHT_UOM'
          disabled={disabled}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volume}>
        <CngTextField
          name="volume"
          label={getTranslatedText('A6A', 'volume')}
          disabled={disabled}
          inputProps={{ maxLength: 15 }}
          onBlur={(e) => { setValue("volume", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volumeUom}>
        <CngCodeMasterAutocompleteField
          name="volumeUom"
          label={getTranslatedText('A6A', 'volumeUom')}
          disabled={disabled}
          codeType='VOLUME_UOM'
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hsCode}>
        <NaHSCodeAutocompleteField
          name="hsCode"
          label={getTranslatedText('A6A', 'hsCode')}
          disabled={disabled}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.undgCode}>
        <NaDangerousGoodsAutocompleteField
          name="undgCode"
          label={getTranslatedText('A6A', 'undgCode')}
          disabled={disabled}
          lookupProps={{
            filters: [{ field: 'indicator', operator: 'equal', value: 'ACI' }]
          }} 
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.markNo}>
        <CngTextField
          name="markNo"
          label={getTranslatedText('A6A', 'markNo')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          onBlur={(e) => { setValue("markNo", e.target.value.trim()) }}
          size="small"
        />
      </CngGridItem>
      {container.length === 0 &&
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNo}>
        <CngTextField
          name="containerNo"
          label={getTranslatedText('A6A', 'containerNo')}
          disabled={disabled}
          inputProps={{ maxLength: 11 }}
          size="small"
        />
      </CngGridItem>
      }
      {container.length > 0 &&
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNo}>
          <CngSelectField
            name="containerNo"
            label={getTranslatedText('A6A', 'containerNo')}
            disabled={disabled}
            items={containerDropDown}
            size='small'
          />
        </CngGridItem>
      }
    </Grid>
	)
}

const TaciOcnA6aShipmentsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TaciOcnA6aShipmentsFormProperties

import React, { useContext } from 'react'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'
import makeValidationSchema from './TaciOcnA6aContainersMakeValidationSchema'
import { useFormContext } from 'react-hook-form'


const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNo: "",
  containerStatus: "",
  containertypeSize: "",
  containertypeSizeOth: "",
  carriageCond: "",
  sealNo: "",
  othContainertypeSize: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap, docFormat }) {
  const { getTranslatedText } = useContext(A6ATranslationContext)
  const { setValue, watch } = useFormContext()
  const containertypeSize = watch('containertypeSize')

  return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNo}>
        <CngTextField
          name="containerNo"
          label={getTranslatedText('A6A', 'containerNo')}
          inputProps={{ maxLength: 11 }}
          disabled={disabled}
          required
          size="small"
        />

      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerStatus}>
        <CngCodeMasterAutocompleteField
          name="containerStatus"
          label={getTranslatedText('A6A', 'containerStatus')}
          disabled={disabled}
          codeType='A6A_CONTAINER_STATUS'
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containertypeSize} >
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={containertypeSize === 'OTHR' ? 6 : 12} shouldHide={shouldHideMap.containertypeSize} >
            <CngCodeMasterAutocompleteField
              key={containertypeSize}
              name='containertypeSize'
              label={getTranslatedText('A6A', 'containertypeSize')}
              disabled={disabled}
              codeType='A6A_CONTAINER_SIZE'
              required={docFormat !== "687"}
              size="small"
            />
          </CngGridItem>

          {containertypeSize === 'OTHR' && <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containertypeSize} >
            <CngTextField
              name="containertypeSizeOth"
              label={getTranslatedText('A6A', 'containertypeSize')}
              inputProps={{ maxLength: 4 }}
              disabled={disabled}
              required={docFormat !== "687"}
              size="small"
              helperText="4 characters valid container size (eg: 20GP)"
            /></CngGridItem>}
        </Grid>
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carriageCond} >
        <CngCodeMasterAutocompleteField
          name="carriageCond"
          label={getTranslatedText('A6A', 'carriageCond')}
          disabled={disabled}
          codeType='A6A_CARRIAGE_COND'
          required={docFormat !== "687"}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={docFormat === "695"}>
        <CngTextField
          name="sealNo"
          label={getTranslatedText('A6A', 'sealNo')}
          inputProps={{ maxLength: 15 }}
          disabled={disabled}
          size="small"
        />
      </CngGridItem>
    </Grid>
  )
}

const TaciOcnA6aContainersFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TaciOcnA6aContainersFormProperties

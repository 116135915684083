import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6aShipmentsDialog from '../TaciOcnA6aShipmentsDialog'
import TaciOcnA6aShipmentsTable from '../TaciOcnA6aShipmentsTable'

function A6AShipmentsSection(props) {
    const { showNotification } = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const [dialog, setDialog] = useState({ open: false, shipment: null })

    const taciOcnA6aShipments = useWatch({ name: 'taciOcnA6aShipments' })

    function handleRemoveShipment(data) {
        const taciOcnA6aShipments = [...getValues('taciOcnA6aShipments')].filter((shipment) =>
            shipment.id ? shipment.id !== data.id : shipment._id !== data._id
        )

        setValue('taciOcnA6aShipments', taciOcnA6aShipments, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('A6A', 'taciOcnA6aShipments') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${taciOcnA6aShipments.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <TaciOcnA6aShipmentsTable
                    data={taciOcnA6aShipments}
                    onAddShipment={() => setDialog({ open: true, shipment: null })}
                    onEditShipment={(shipment) => setDialog({ open: true, shipment })}
                    onDeleteShipment={handleRemoveShipment}
                />

                <TaciOcnA6aShipmentsDialog
                    onClose={() => setDialog({ open: false, shipment: null })}
                    open={dialog.open}
                    shipment={dialog.shipment}
                    showNotification={showNotification}
                />
            </CngSection>
        </Grid>
    )
}

export default A6AShipmentsSection
import React, { useRef, useEffect, useContext } from 'react'
import { Grid, Card, Box, Tooltip } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useFormContext, useWatch } from 'react-hook-form'
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6aPartiesFormProperties from '../TaciOcnA6aPartiesFormProperties'

const { button: { CngIconButton } } = components

const { initialValues } = TaciOcnA6aPartiesFormProperties.formikProps

function A6APartiesSection() {
    const { getValues, setValue } = useFormContext()
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const taciOcnA6aParties = useWatch({ name: 'taciOcnA6aParties' })
    const lastIndex = useRef(taciOcnA6aParties.length - 1)

    // Push party info default value with crew type 'Shipper' or 'Consignee' if there isn't any
    useEffect(() => {
        const { initialValues } = TaciOcnA6aPartiesFormProperties.formikProps

        if (taciOcnA6aParties.length === 0) {
            setValue('taciOcnA6aParties', [
                { ...initialValues, partyType: 'CZ', _id: 1 },
                { ...initialValues, partyType: 'CN', _id: 2 }
            ])
        } else {
            const hasShipper = taciOcnA6aParties.findIndex(party => party.partyType === 'CZ') !== -1
            const hasConsignee = taciOcnA6aParties.findIndex(party => party.partyType === 'CN') !== -1

            if (!hasShipper || !hasConsignee) {
                const result = [...getValues('taciOcnA6aParties')]

                if (!hasShipper) {
                    result.push({ ...initialValues, partyType: 'CZ' })
                } else if (!hasConsignee) {
                    result.push({ ...initialValues, partyType: 'CN' })
                }

                setValue('taciOcnA6aParties', result.map((party, index) => ({ ...party, _id: index + 1 })))
            }
        }
    }, [taciOcnA6aParties])


    function handleAddPartyInfo(data) {
        const taciOcnA6aParties = [...getValues('taciOcnA6aParties'), { ...data, _id: ++lastIndex.current }]
        setValue('taciOcnA6aParties', taciOcnA6aParties)
    }

    function handleDeletePartyInfo(data) {
        const taciOcnA6aParties = [...getValues('taciOcnA6aParties')].filter((party) =>
            party.id ? party.id !== data.id : party._id !== data._id
        )
        setValue('taciOcnA6aParties', taciOcnA6aParties)
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6A', 'taciOcnA6aParties')}>
                <Grid container spacing={2}>
                    {taciOcnA6aParties.map((party, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>
                                        <Grid item xs={12}>
                                            <TaciOcnA6aPartiesFormProperties.Fields index={index} />
                                        </Grid>

                                        <Grid item xs='auto'>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Add'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'plus']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo(initialValues)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Clone'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'copy']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo({ ...party, id: undefined })}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Delete'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'trash']} size='small' type='outlined'
                                                                onClick={() => handleDeletePartyInfo(party)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CngSection>
        </Grid>
    )

}

export default A6APartiesSection
import React, { useContext, useCallback } from 'react'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  previousCcn: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ disabled, shouldHideMap, index }) {
  const { getTranslatedText } = useContext(A6ATranslationContext)

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `a6aPrevious.${index}.${field}` : field
  }, [])


	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.previousCcn}>
            <CngTextField
          name={getFieldName('previousCcn')}
          label={getTranslatedText('A6A', 'previousCcn')}
              disabled={disabled}
          inputProps={{ maxLength: 26 }}
          size='small'
          required
            />
          </CngGridItem>
    </Grid>
	)
}

const A6aPreviousFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default A6aPreviousFormProperties

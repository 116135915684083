import React, { useRef, useContext } from 'react'
import { Grid, Box, Card, Tooltip } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import A6aExportsFormProperties from '../A6aExportsFormProperties'

const { button: { CngIconButton } } = components

const { initialValues } = A6aExportsFormProperties.formikProps

function A6AExportsSection() {
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const a6aExports = useWatch({ name: 'a6aExports' })
    const lastIndex = useRef(a6aExports.length - 1)

    function handleAddExports(data) {
        const a6aExports = [...getValues('a6aExports'), { ...data, _id: ++lastIndex.current }]
        setValue('a6aExports', a6aExports)
    }

    function handleDeleteExports(data) {
        const a6aExports = [...getValues('a6aExports')].filter((exports) =>
            exports.id ? exports.id !== data.id : exports._id !== data._id
        )

        setValue('a6aExports', a6aExports)
    }


    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6A', 'a6aExports')} alignItems='flex-end'>
                <Grid container spacing={1}>
                    {a6aExports.map((exports, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>

                                        <Grid item xs={10}>
                                            <A6aExportsFormProperties.Fields index={index} />
                                        </Grid>

                                        <Grid item xs='auto'>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Clone'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'copy']} size='small' type='outlined'
                                                                onClick={() => handleAddExports({ ...exports, id: undefined })}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Delete'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'trash']} size='small' type='outlined'
                                                                onClick={() => handleDeleteExports(exports)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs='auto'>
                                <Tooltip placement='bottom' title='Add new'>
                                    <span>
                                        <CngIconButton
                                            icon={['fal', 'plus']}
                                            onClick={() => handleAddExports(initialValues)}
                                            size='small'
                                            type='outlined'
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default A6AExportsSection
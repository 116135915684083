import React, { useRef, useState, useEffect } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import pathMap from 'src/paths/pathMap'
import A6aApiUrls from 'src/apiUrls/A6aApiUrls'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import CustomButtonSection from 'src/views/common/CustomButtonSection'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from '../../common/FileForUserCommon'
import FormProperties from './FormProperties'
import ChargeTypeDialog from 'src/views/common/ChargeTypeDialog'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngEditForm },
  table: { useFetchCodeMaintenanceLookup }
} = components

const { filter: { EQUAL } } = constants

function A6aEditPage(props) {
  const { history, onSetLoading, showNotification, id, setCCNValue } = props
  const serverDataRef = useRef()
  const { securedSendRequest } = useServices()

  const [isSuperParty, setSuperParty] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: {} })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function preSubmitA6A(data) {
    if (data && data.taciOcnA6aContainers) {
      const updatedContainers = data.taciOcnA6aContainers.map(cont =>
        cont.containertypeSize === 'OTHR' ? { ...cont, containertypeSize: cont.containertypeSizeOth, othContainertypeSize: true } : { ...cont, othContainertypeSize: false }
      )
      data.taciOcnA6aContainers = updatedContainers;
    }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: data })
    } else {
      data.chargeAs = 'REGULAR'
      handleSubmitA6A(data)
    }
  }

  function handleSubmitA6A(data) {
    onSetLoading(true)

    const fileForUserDetails = FileForUserGetUserDetails()
    if (fileForUserDetails) {
      const { fileForUserId, fileForUserLoginId, fileForUserPartyId } = fileForUserDetails
      data.fileForUserId = fileForUserId
      data.fileForUserLoginId = fileForUserLoginId
      data.fileForUserPartyId = fileForUserPartyId
    }

    securedSendRequest.execute('POST', A6aApiUrls.SUBMIT, data,
      (response) => {
        let data = response.data
        let errorMessages = []
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          errorMessages.push(data.errorMessages);
          setErrorMessageDialog({ open: true, errorMessages })
        } else {
          showNotification('success', 'A6A submitted successfully.')
          history.push(pathMap.A6A_LIST_VIEW)
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'A6A submit failed.')
      },
      () => onSetLoading(false)
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields disabled={false} showNotification={showNotification} />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: A6aApiUrls.GET,
            onPreSuccess: (serverData) => {
              serverDataRef.current = serverData
              let fileForUserDetails = FileForUserGetUserDetails();
              if (fileForUserDetails != null && fileForUserDetails != undefined) {
                serverDataRef.current.fileForUserId = fileForUserDetails.fileForUserId;
                serverDataRef.current.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                serverDataRef.current.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
              }
              setCCNValue(serverData.ccn)
            }
          }}
          update={{ url: A6aApiUrls.PUT }}
          id={id}
          renderButtonSection={(_, loading) => (
            <A6AEditPageButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
              showNotification={showNotification}
              serverDataRef={serverDataRef}
              isSuperParty={isSuperParty}
              onSubmitA6A={preSubmitA6A}
            />
          )}
        />
      </Grid>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: {} })}
        onSubmit={handleSubmitA6A}
        open={submitDialog.open}
        items={submitDialog.items}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </Grid>
  )
}

export default A6aEditPage

function A6AEditPageButtonSection(props) {
  const { history, loading, onSetLoading, showNotification, serverDataRef, onSubmitA6A } = props
  const { getValues } = useFormContext()

  const [confirmDialog, setConfirmDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton color='primary' disabled={loading} type='submit' size='medium' shouldShowProgress={loading}>
                Update
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton color='primary' disabled={loading} size='medium' onClick={() => onSubmitA6A(getValues())}>Submit</CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.A6A_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}

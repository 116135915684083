import { ThemeLibrary as CngThemeLibrary } from 'cng-web-lib'
import upsColorConfiguration from 'src/theme/upsColorConfiguration'
import defaultTheme from 'src/assets/defaultTheme.js'

class ThemeLibrary extends CngThemeLibrary {

  constructor(names,configurations){
    super()

    if(names){
      this._names = names
    }else{
      this._names = super.getNames()
    }

    if(configurations){
      this._configurations = configurations
    } else{
      if (window.location.pathname.includes('/ups')) {
        this._configurations = super.getConfigurations(upsColorConfiguration)
      } else {
        this._configurations = defaultTheme
      }
    }
  }

  getNames() {
    return this._names
  }

  getConfigurations() {
    return this._configurations
  }
}

export default ThemeLibrary

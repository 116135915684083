
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import A6aViewPage from './A6aViewPage'
import A6aApiUrls from 'src/apiUrls/A6aApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const { filter: { EQUAL, IN } } = constants

function ViewPage(props) {
  const { history, showNotification } = props
  const { id } = useParams()
  const { fetchRecord, fetchRecords } = useServices()

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(A6aApiUrls.GET, id, (res) => res.content[0]),

      // Document Type
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'A6A_DOC_TYPE' }],
        undefined, 'code'
      ),
      // Mode Of Transport
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'A6A_MODE_OF_TRANSPORT' }],
        undefined, 'code'
      ),
      // Customs Procedure
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{
          field: 'codeType', operator: IN,
          value: ['A6A_CUSTOMS_PROCEDURE_EXPORT', 'A6A_CUSTOMS_PROCEDURE_IMPORT', 'A6A_CUSTOMS_PROCEDURE_EMPTY', 'A6A_CUSTOMS_PROCEDURE_SUPP']
        }], undefined, 'code'
      ),
      // Supplement Requirement Indicator
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'A6A_SUPP_REQ_IND' }],
        undefined, 'code'
      ),

      // Countries
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_MANIFEST_COUNTRY' }],
        undefined, 'code'
      ),
      // States
      fetchRecords.execute(NaCodeMaintenanceApiURLs.GET, {
        filters: [{ field: 'indicator', operator: EQUAL, value: 'ACI' }],
        customData: { codeMType: 'CountryStateCode' }
      }, (res) => res.content),
      // First expected port
      fetchRecords.execute(NaCodeMaintenanceApiURLs.GET,
        { customData: { codeMType: 'CustomsOffice' } },
        (res) => {
          const result = {}
          res.content.forEach((item) => { result[item.code] = item.descriptionEn })
          return result
        }
      ),
      // Sub Location
      fetchRecords.execute(NaCodeMaintenanceApiURLs.GET,
        { customData: { codeMType: 'Sublocation' } },
        (res) => {
          const result = {}
          res.content.forEach((item) => { result[item.code] = item.descriptionEn })
          return result
        }
      ),

      // Party Type
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'A6A_PARTY_TYPE' }],
        undefined, 'code'
      )
    ]).then(([
      data,
      docType, mot, customsProc, supReq,
      countries, countryStates, customsOffice, subLoc,
      partyType
    ]) => {
      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.A6A_LIST_VIEW)
      }

      const states = Object.keys(countries).reduce((acc, countryCode) => {
        acc[countryCode] = countryStates.filter(
          (state) => state.code === countryCode
        )
        return acc
      }, {})

      setData({ ...data })
      setLookups({
        docType, mot, customsProc, supReq,
        countries, countryStates: states, customsOffice, subLoc,
        partyType
      })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  if (data === null) {
    return <CircularProgress />
  }

  return (
    <>
      <Card>
        <CardContent>
          <A6aViewPage id={id} data={data}
            getCountryStateLabel={getCountryStateLabel}
            getLookupValue={getLookupValue}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton color='secondary' onClick={() => history.push(pathMap.A6A_LIST_VIEW)}>
            Back to A6A list
          </CngButton>
        </CardActions>
      </Card>
    </>
  );
}

export default ViewPage

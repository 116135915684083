import React, { useContext } from "react";
import MaterialTable from "material-table"
import { A6ATranslationContext } from './contexts/A6ATranslationContext'

function ErrorResponse({ errorCode, fieldDesc, rejectType, errorDesc, responseText }) {
  const { getTranslatedText } = useContext(A6ATranslationContext)

    return (
      <span>
        <MaterialTable
        options={{
            paging: false,
            showTitle: false,
            search: false,
            sorting: false,
            draggable: false,
            toolbar: false
        }}
        columns={[
          { title: getTranslatedText('A6A', 'errorCode'), field: "code" },
          { title: getTranslatedText('A6A', 'fieldDesc'), field: "fieldDesc" },
          { title: getTranslatedText('A6A', 'rejectType'), field: "rejectType" },
          { title: getTranslatedText('A6A', 'errorDesc'), field: "description" },
          { title: getTranslatedText('A6A', 'responseText'), field: "text" },
        ]}
        data={[
          {
            code: errorCode,
            fieldDesc: fieldDesc,
            rejectType: rejectType,
            description: errorDesc,
            text: responseText
          }
        ]}
      />
      </span>
    )
  }
  export default ErrorResponse;
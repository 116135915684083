import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import moment from 'moment'
const {
  locale: {
    key: { CommonValidationMessageKeys }
  },
} = constants

const today = new Date();
today.setHours(0, 0, 0, 0);

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  ) 

  return Yup.object(
    {
    docFormat: Yup.string().required(requiredMessage).nullable(),
    carrierCode: Yup.string().required(requiredMessage).min(4, "Max and Min length allowed is 4").matches(/^[89].*$/, "Should start with 8 or 9").matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc."),
    uniqueConsignmentRefNo: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(35, "Max length allowed is 35").nullable(),
    ccn: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").matches(/^[89].*$/, "Should start with 8 or 9").max(25, "Max length allowed is 25").nullable(),
    originalCcn: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat === '687'),
        then: Yup.string().required("Original CCN is required for Supplementary").matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").matches(/^[89].*$/, "should start with 8 or 9").max(25, "Max length allowed is 25"),
      }).nullable(),
    mot: Yup.string().required(requiredMessage).nullable(),
    customsProcedure: Yup.string().required(requiredMessage).nullable(),
    bolNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").max(35, "Maximum length allowed is 35").nullable(),
    specialIns: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(60, "Maximum length allowed is 60").nullable(),
    supplementaryReqInd: Yup.string().when('docFormat', {
      is: (docFormat) => ((docFormat === '83')),
      then: Yup.string().required(requiredMessage)
    }).nullable(),
    bulkShipment: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat !== '695'),
      then: Yup.string().required(requiredMessage)
    }).nullable(),
    a6ConvRefNo: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat !== '687'),
        then: Yup.string().notOneOf([Yup.ref('ccn'), null], "Cannot be same as CCN").required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").
          matches(/^[9].*$/, "Should start with 9").max(25, "Max length allowed is 25"),
      }).nullable(),
    vesselName: Yup.string().when('docFormat', {
        is: (docFormat) => (docFormat === '83' || docFormat === '695' || docFormat === '711'),
        then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").max(35, "Max length allowed is 35"),
      }).nullable(),
    voyageNo: Yup.string().when('docFormat', {
        is: (docFormat) => (docFormat === '83' || docFormat === '695' || docFormat === '711'),
        then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(17, "Max length allowed is 17"),
        otherwise: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(17, "Max length allowed is 17"),
      }).nullable(),
    portOfLoading: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat !== '687'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    terminalOfLoading: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat === '711'),
        then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").max(30, "Max Length allowed is 30"),
      }).nullable(),
    pierOfLoading: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat === '711'),
        then: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(4, "Max Length allowed is 4"),
      }).nullable(),
    //vesselDepartureDate:  Yup.date().validFormat().typeError(dateTypeErrorMessage).required("Vessel Departure Date is required for Export"),
    customsOfficeOfExit: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat === '711'),
      then: Yup.string().required("Customs office of Exit is required for Export"),
    }).nullable(),
    portOfArrival: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat === '695' || docFormat === '83'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),

    estimatedLoadingDate: Yup.string().when('docFormat', {
      is: (docFormat) => docFormat === "83",
      then: Yup.string().when('estimatedLoadingDate', {
        is: (estimatedLoadingDate) => estimatedLoadingDate !== '' && estimatedLoadingDate !== undefined,
        then: Yup.string().test(
          'estimatedLoadingDate',
          'Please select a valid Date Time in yyyy-mm-dd HH:mm:ss format.',
          value => {
            var formattedDate = moment(value).format('YYYY-MM-DD')
            return moment(formattedDate).isValid();
          },
        )
      })
    }).nullable(),
    vesselDepartureDate: Yup.string().when('docFormat', {
      is: (docFormat) => docFormat === "711",
          then: Yup.string().required(requiredMessage).test(
            'vesselDepartureDate',
            'Please select a valid Date Time in yyyy-mm-dd HH:mm:ss format.',
            value => {
              var formattedDate = moment(value).format('YYYY-MM-DD')
              var formattedToday = moment(today).format('YYYY-MM-DD')
              return moment(formattedDate).isValid();
            },
          )
        }).nullable(),
    foreignPortOfLading: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat !== '687'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    cargoLoadingCity: Yup.string().when('docFormat', {
          is: (docFormat) => (docFormat !== '687'),
          then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(25, "Max Length allowed is 25"),
          otherwise: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(25, "Max Length allowed is 25"),
        }).nullable(),
    cargoLoadingPort: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat !== '687'),
          then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(25, "Max Length allowed is 25"),
        }).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(25, "Max Length allowed is 25").nullable(),
    customsOffOfPortArrival: Yup.string().when(['docFormat', 'customsProcedure'], {
      is: (docFormat, customsProcedure) => ((docFormat === '83' && customsProcedure !== '26') || docFormat === '695'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    customsOffOfPortArrivalSubLocCode: Yup.string().when(['docFormat', 'customsProcedure'], {
      is: (docFormat, customsProcedure) => (docFormat === '83' && customsProcedure !== '26'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    customsOffOfManifestDestination: Yup.string().when(['docFormat', 'customsProcedure'], {
      is: (docFormat, customsProcedure) => ((docFormat === '83' && customsProcedure !== '26') || docFormat === '695'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    customsOffOfManifestDestinationSubLocCode: Yup.string().when(['docFormat', 'customsProcedure'], {
      is: (docFormat, customsProcedure) => (docFormat === '83' && customsProcedure !== '26'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    cargoDestinationCountry: Yup.string().when('docFormat', {
          is: (docFormat) => (docFormat === '83' || docFormat === '695' || docFormat === '711' || docFormat === '687'),
          then: Yup.string().required(requiredMessage),
        }).nullable(),
    cargoDestinationCity: Yup.string().when('docFormat', {
          is: (docFormat) => (docFormat === '83' || docFormat === '695' || docFormat === '711' || docFormat === '687'),
          then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").max(25, "Max length allowed is 25"),
        }).nullable(),
    cargoDestinationPort: Yup.string().when('docFormat', {
          is: (docFormat) => (docFormat === '83' || docFormat === '695' || docFormat === '711'),
          then: Yup.string().required(requiredMessage),
        }).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(25, "Max length allowed is 25").nullable(),
    customsOffOfDischarge: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").nullable(),
    arrivalTerminal: Yup.string().when('docFormat', {
          is: (docFormat) => (docFormat === '83' || docFormat === '695'),
          then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").max(30, "Max length allowed is 30"),
        }).nullable(),
    pierOfArrival: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat === '83' || docFormat === '695'),
      then: Yup.string().matches(/^[0-9]+$/, "Only digits are allowed").max(4, "Max length allowed is 4"),
    }).nullable(),
    portOfArrival: Yup.string().when('docFormat', {
          is: (docFormat) => (docFormat === '83' || docFormat === '695'),
          then: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like )(@*:etc.").max(30, "Max length allowed is 30"),
        }).nullable(),
    carrierName: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(25, "Max length allowed is 25").nullable(),
    terminalName: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(4, "Max length allowed is 4").nullable(),
    portOfDischarge: Yup.string().when('docFormat', {
      is: (docFormat) => (docFormat === '711'),
      then: Yup.string().required("Port of Discharge is required for Export"),

    }).nullable(),


    //taciOcnA6aContainers: Yup.array(),
    a6aExports: Yup.array(
      Yup.object({
        exportTransNo: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(26, "Max length allowed is 26").nullable(),
      })
    ),

    a6aPrevious: Yup.array(
      Yup.object({
        previousCcn: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(26, "Max length allowed is 26").nullable()
      })
    ),

    taciOcnA6aParties: Yup.array(
      Yup.object({
        saveFlag: Yup.boolean().nullable(),
        partyIdentification: Yup.string().when('saveFlag', {
          is: false,
          then: schema => schema,
          otherwise: Yup.string().when('saveFlag', {
            is: true,
            then: Yup.string().min(1, "Max length allowed is 1.").max(35, "Max length is 35.").required(requiredMessage),
          })
        }).nullable(),
        partyType: Yup.string().required(requiredMessage).nullable(),
        partyName1: Yup.string().trim().max(71, "Max length allowed is 71.").required(requiredMessage),
        partyName2: Yup.string().trim().max(71, "Max length allowed is 71.").nullable(),
        address1: Yup.string().trim().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(175, "Max length allowed is 175.").required(requiredMessage),
        address2: Yup.string().trim().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(175, "Max length allowed is 175.").nullable(),
        city: Yup.string().required(requiredMessage).max(35, " Max length allowed is 35").matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like ),(,@ etc.").nullable(),
        countryCode: Yup.string().required(requiredMessage).nullable(),
        postalCode: Yup.lazy((value) => value === '' ? Yup.string() : Yup.string().when('countryCode', {
          is: 'CA',
          then: Yup.string().trim().max(9, "Max length allowed is 9.").required("Please enter a value for Postal Code.").matches("[a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z][0-9]$", "Please enter Postal Code in the format: alpha+num+alpha+num+alpha+num (e.g. A1B1C1)."),
          otherwise: Yup.string().when('countryCode', {
            is: 'US',
            then: Yup.string().trim().max(9, "Max length allowed is 9.").required("Please enter a value for Postal Code.").matches("[a-zA-Z0-9]$", "Only alphanumeric values are allowed."),
          })
        }).nullable().matches("[a-zA-Z0-9]+$", "Only alphanumeric values are allowed.")),
        stateCode: Yup.string().when('countryCode', {
          is: (countryCode) => (countryCode === 'US' || countryCode === 'CA'),
          then: Yup.string().trim().required("Please enter a value for Province/State Code.").matches("[a-zA-Z]$", "Only alpha values are allowed."),
          otherwise: Yup.string().when('countryCode', {
            is: (countryCode) => (countryCode !== 'US' || countryCode !== 'CA'),
            then: Yup.string().trim().nullable().matches("[a-zA-Z]*$", "Only alpha values are allowed."),
          }),
        }).nullable(),
        contactPerson: Yup.string().when('partyType', {
          is: (partyType) => (partyType === 'DP'),
          then: Yup.string().required("Contact name is mandatory for Delivery Party").matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like ),(,@ etc.").max(35, "Maximum length allowed is 35"),
        }).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like ),(,@ etc.").max(35, "Maximum length allowed is 35").nullable()
        .test('both-or-neither', 'Both Contact Person and Telephone Number must be provided or both must be empty', function(value) {
          const { telNo } = this.parent;
          if ((value && !telNo) || (!value && telNo)) {
            return false;
          }
          return true;
        }),
        telNo: Yup.lazy((value) => value === '' ? Yup.string() : Yup.string().nullable().max(12, "Max 12 characters are allowed").min(5, "Min 5 characters are allowed").matches("^[0-9]*$", "Phone enter valid phone number")
        .test('both-or-neither', 'Both Contact Person and Telephone Number must be provided or both must be empty', function(value) {
          const { contactPerson } = this.parent;
          if ((value && !contactPerson) || (!value && contactPerson)) {
            return false;
          }
          return true;
        })),
      })
    ),

    messageFunction: Yup.string().when('a6aStatus', {
      is: (value) => value === "1001" || value === "1004",
      then: Yup.string().test('messageFunction', 'Rejected record should submit as Original.', function (value) {
        if (value != 9) {
          return false;
        }
            else {
              return true;
            }
          }),
          otherwise: Yup.string().when('a6aStatus', {
            is: (value) => value === "1003",
            then: Yup.string().test('messageFunction', 'Cancelled record should submit as Original.', function (value) {
              if (value != 9) {
                return false;
              }
              else {
                return true;
              }
            }),
            otherwise: Yup.string().when('a6aStatus', {
              is: (value) => value === "1000",
              then: Yup.string().test('messageFunction', 'Accepted record cannot submit as Original.', function (value) {
                if (value == 9) {
                  return false;
                }
                else {
                  return true;
                }
              })
            })
          })
        }).nullable().required(requiredMessage),

    templateFlag: Yup.boolean(),
    templateName: Yup.string().when('templateFlag', {
      is: true,
      then: Yup.string().max(1, "Max length allowed is 1").max(50, "Max length is 50").required(requiredMessage).matches("[a-zA-Z0-9\s]$", "Only alphanumeric characters are allowed."),
    }).nullable(),
    a6aStatus: Yup.string().nullable()

  })
}

export default makeValidationSchema

import { makeStyles } from '@material-ui/core'

const useCheckboxStyles = makeStyles((theme) => ({
  icon: {
    width: 20,
    height: 20,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    boxShadow: 'inset 0 0 0 1px #5f5753'
  },
  checkedIcon: {
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
      backgroundImage: `url(
        "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%230a8080'/%3E%3C/svg%3E"
      )`,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto'
    }
  },
  indeterminateIcon: {
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
      backgroundImage: `url(
        "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19 13H5v-2h14v2z' fill='%230a8080'/%3E%3C/svg%3E"
      )`,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto'
    }
  }
}))

export { useCheckboxStyles }

import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormContext,useWatch } from "react-hook-form";
import { Grid } from '@material-ui/core'
import { DataFlattener } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon';

import A6AHeaderSection from './Sections/A6AHeaderSection'
import A6AScheduleSection from './Sections/A6AScheduleSection'
import A6APrintingSection from './Sections/A6APrintingSection'
import A6AExportsSection from './Sections/A6AExportsSection'
import A6APreviousCCNSection from './Sections/A6APreviousCCNSection'
import A6APartiesSection from './Sections/A6APartiesSection'
import A6AContainersSection from './Sections/A6AContainersSection'
import A6AShipmentsSection from './Sections/A6AShipmentsSection'
import A6ASNSection from './Sections/A6ASNSection'
import A6AMessageFunctionSection from './Sections/A6AMessageFunctionSection'


const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: null,
  version: 0,
  messageFunction: "9",
  chargingOption: "",
  recordStatus: 0,
  docFormat: "",
  carrierCode: "",
  uniqueConsignmentRefNo: "",
  ccn: "",
  originalCcn: "",
  mot: "",
  customsProcedure: "",
  bolNo: "",
  specialIns: "",
  supplementaryReqInd: "",
  bulkShipment: "",
  a6ConvRefNo: "",
  vesselName: "",
  voyageNo: "",
  portOfLoading: "",
  terminalOfLoading:"",
  pierOfLoading:"",
  estimatedLoadingDate: "",
  vesselDepartureDate: "",
  customsOfficeOfExit: "",
  portOfArrival: "",
  pierOfArrival: "",
  foreignPortOfLading: "",
  cargoLoadingCity: "",
  cargoLoadingPort: "",
  customsOffOfPortArrival: "",
  customsOffOfPortArrivalSubLocCode: "",
  customsOffOfManifestDestination: "",
  customsOffOfManifestDestinationSubLocCode: "",
  cargoDestinationCountry: "",
  cargoDestinationCity: "",
  cargoDestinationPort: "",
  customsOffOfDischarge: "",
  arrivalTerminal: "",
  carrierName: "",
  terminalName: "",
  portOfDischarge: "",
  templateFlag: false,
  templateName: "",
  a6aStatus: "",
  supplementaryMatch: "",
  riskAssessmentStatus: "",
  a6aExports: [],
  a6aPrevious: [],
  taciOcnA6aParties: [],
  taciOcnA6aShipments: [],
  taciOcnA6aContainers: [],
  taciOcnA6aSN: [],
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  createdByLoginId:""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const location = useLocation();

  const { setValue } = useFormContext();

  const docFormat = useWatch({ name: "docFormat" })
  const bulkShipment = useWatch({ name: "bulkShipment" })

  const addPage = location.pathname.toString().indexOf("add") != -1 ? true : false
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false

  useWatch({ name: "fileForUserId" })
  useWatch({ name: "fileForUserLoginId" })
  useWatch({ name: "fileForUserPartyId" })
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);
  
  function shouldShowScheduleSection() {
    return docFormat === "83" || docFormat === "711" || docFormat === "695" || docFormat === "687"
  }

  function shouldShowContainersSection() {
    return bulkShipment === "N" || docFormat === "695"
  }

  const memoedA6AHeaderSection = useMemo(() => (
    <A6AHeaderSection disabled={disabled} shouldHideMap={shouldHideMap} />
  ), [])

  const memoedA6AScheduleSection = useMemo(() => (
    <A6AScheduleSection disabled={disabled} shouldHideMap={shouldHideMap} />
  ), [])

  const memoedA6APrintingSection = useMemo(() => (
    <A6APrintingSection disabled={disabled} shouldHideMap={shouldHideMap} editPage={editPage} />
  ), [])

  const memoedA6AExportsSection = useMemo(() => (
    <A6AExportsSection />
  ), [])

  const memoedA6APreviousCCNSection = useMemo(() => (
    <A6APreviousCCNSection />
  ), [])

  const memoedA6APartiesSection = useMemo(() => (
    <A6APartiesSection />
  ), [])

  const memoedA6AContainersSection = useMemo(() => (
    <A6AContainersSection showNotification={showNotification} />
  ), [])

  const memoedA6AShipmentsSection = useMemo(() => (
    <A6AShipmentsSection showNotification={showNotification} />
  ), [])

  const memoedA6ASNSection = useMemo(() => (
    <A6ASNSection />
  ), [])

  const memoedA6AMesageFunctionSection = useMemo(() => (
    <A6AMessageFunctionSection disabled={disabled} shouldHideMap={shouldHideMap} addPage={addPage} />
  ), [])

  return (
    <Grid container spacing={3}>

      {memoedA6AHeaderSection}
      {shouldShowScheduleSection() && memoedA6AScheduleSection}
      {memoedA6APrintingSection}

      {docFormat === "711" && memoedA6AExportsSection}
      {docFormat === "711" && memoedA6APreviousCCNSection}

      {memoedA6APartiesSection}

      {shouldShowContainersSection() && memoedA6AContainersSection}
      {docFormat !== "695" && memoedA6AShipmentsSection}

      {memoedA6ASNSection}

      {memoedA6AMesageFunctionSection}
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  if (serverData.taciOcnA6aContainers) {
    const updatedContainers = serverData.taciOcnA6aContainers.map(cont =>
      cont.othContainertypeSize ? { ...cont, containertypeSizeOth: cont.containertypeSize, containertypeSize: 'OTHR' } : cont
    )
    serverData.taciOcnA6aContainers = updatedContainers;
  }

  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  if (localData && localData.taciOcnA6aContainers) {
    const updatedContainers = localData.taciOcnA6aContainers.map(cont =>
      cont.containertypeSize === 'OTHR' ? { ...cont, containertypeSize: cont.containertypeSizeOth, othContainertypeSize: true } : { ...cont, othContainertypeSize: false }
    )
    localData.taciOcnA6aContainers = updatedContainers;
  }
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})



export default FormProperties

import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    containerNo: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").min(11 , "Container length should be 11").max(11, "Container length should be 11").nullable(),
    containerStatus: Yup.string().required(requiredMessage).nullable(),
    containertypeSize: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Cannot contain special characters.").min(4, "Min length allowed is 4").max(4, "Max length allowed is 4").nullable(),
    containertypeSizeOth: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Cannot contain special characters.").min(4, "Min length allowed is 4").max(4, "Max length allowed is 4").nullable(),
    carriageCond: Yup.string().nullable(),
    sealNo: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").max(15,"Max length allowed is 15").nullable()
  })
}

export default makeValidationSchema

import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import TaciOcnA6aSNDialog from '../TaciOcnA6aSNDialog'
import TaciOcnA6aSNTable from '../TaciOcnA6aSNTable'

function A6ASNSection() {
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const [dialog, setDialog] = useState({ open: false, sn: null })

    const taciOcnA6aSN = useWatch({ name: 'taciOcnA6aSN' })

    function handleRemoveSN(data) {
        const taciOcnA6aSN = [...getValues('taciOcnA6aSN')].filter((sn) =>
            sn.id ? sn.id !== data.id : sn._id !== data._id
        )

        setValue('taciOcnA6aSN', taciOcnA6aSN, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('A6A', 'taciOcnA6aSN') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${taciOcnA6aSN.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <TaciOcnA6aSNTable
                    data={taciOcnA6aSN}
                    onAddSN={() => setDialog({ open: true, sn: null })}
                    onEditSN={(sn) => setDialog({ open: true, sn })}
                    onDeleteSN={handleRemoveSN}
                />

                <TaciOcnA6aSNDialog
                    onClose={() => setDialog({ open: false, sn: null })}
                    open={dialog.open}
                    sn={dialog.sn}
                />
            </CngSection>
        </Grid>
    )
}

export default A6ASNSection
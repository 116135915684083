import AddPage from './AddPage.js'
import { BasePage as CngBasePage } from 'cng-web-lib'
import EditPage from './EditPage.js'
import React from 'react'
import TablePage from './TablePage.js'
import ViewPage from './ViewPage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'
import A6ATranslationProvider from './contexts/A6ATranslationContext.js'

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage renderPage={(showSnackbar) => (
      <A6ATranslationProvider>
        <TablePage showNotification={showSnackbar} {...props} />
      </A6ATranslationProvider>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (    
    <CngBasePage renderPage={(showSnackbar) => (
      <A6ATranslationProvider>
        <AddPage showNotification={showSnackbar} {...props} />
      </A6ATranslationProvider>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedEditPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (    
    <CngBasePage renderPage={(showSnackbar) => (
      <A6ATranslationProvider>
        <EditPage showNotification={showSnackbar} {...props} />
      </A6ATranslationProvider>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (   
    <CngBasePage renderPage={(showSnackbar) => (
      <A6ATranslationProvider>
        <ViewPage showNotification={showSnackbar} {...props} />
      </A6ATranslationProvider>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedAddPage as AddPage,
  WrappedEditPage as EditPage,
  WrappedViewPage as ViewPage,
  WrappedTablePage as TablePage
}

import Namespace from 'src/constants/locale/Namespace'
import ProductMasterKeys from 'src/constants/locale/key/ProductMaster'
import PartyMasterKeys from 'src/constants/locale/key/PartyMaster'
import pathMap from 'src/paths/pathMap'
import ArrivalmessageKeys from 'src/constants/locale/key/Arrivalmessage'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import { constants, useTranslation } from 'cng-web-lib'
import AcioceanKeys from 'src/constants/locale/key/Aciocean'
import ConveyanceKeys from 'src/constants/locale/key/Conveyance'
import A6aKeys from 'src/constants/locale/key/A6a'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import StatusMessageKeys from 'src/constants/locale/key/StatusMessage'
import H2hSubFailsKeys from 'src/constants/locale/key/H2hSubFails'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import FlightMatrixKeys from 'src/constants/locale/key/FlightMatrix'
import HSCodeKeys from 'src/constants/locale/key/HSCODE'
import ValidationErrorLogKeys from 'src/constants/locale/key/ValidationErrorLog'
import DbadminAciAirKeys from 'src/constants/locale/key/DBAdminAciAir'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import SbciProductMasterKeys from 'src/constants/locale/key/SbciProductMaster'
import SbciManifestKeys from 'src/constants/locale/key/SbciManifest'
import EquipmentMasterKeys from 'src/constants/locale/key/EquipmentMaster'
import ConveyanceMasterKeys from 'src/constants/locale/key/ConveyanceMaster'
import AciHighwayStatQryKeys from 'src/constants/locale/key/AciHighwayStatusQuery'
import CrewMasterKeys from 'src/constants/locale/key/CrewMaster'
import UsProductMasterKeys from 'src/constants/locale/key/UsProductMaster'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import AciHighwayResponseKeys from 'src/constants/locale/key/AciHighwayResponse'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHwyInbondArrivalKeys from 'src/constants/locale/key/AceHwyInbondArrival'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import AceHighwayAbiInbondEventKeys from 'src/constants/locale/key/AceHighwayAbiInbondEvent'
import EhblManifestKeys from 'src/constants/locale/key/EhblManifest'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import AciHwReleaseNoticeKeys from 'src/constants/locale/key/AciHwReleaseNotice'
import DbAdminStatusConfigKeys from 'src/constants/locale/key/DbAdminStatusConfig'
import NBCIUSCAPartyMasterKeys from 'src/constants/locale/key/NBCIUSCAPartyMaster'
import UsDeclarationsKeys from 'src/constants/locale/key/UsDeclarations'
import FaxTrackerKeys from 'src/constants/locale/key/FaxTracker'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'
import NbciUsInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice'
import BillingConfigKeys from './constants/locale/key/BillingConfig'
import AnnouncementKeys from 'src/constants/locale/key/Announcement'
import SbciFileUploadHistoryKeys from './constants/locale/key/SbciFileUploadHistory'
import AceIsfKeys from './constants/locale/key/AceIsf'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'
import AceOcnBillofladingKeys from 'src/constants/locale/key/AceOcnBilloflading'
import AceOcnVesselMasterKeys from 'src/constants/locale/key/AceOcnVesselMaster'
import AceOcnVesselArrivalKeys from 'src/constants/locale/key/AceOcnVesselArrival'
import AceOcnVesselDepartureKeys from 'src/constants/locale/key/AceOcnVesselDeparture'
import PartyConfigKeys from 'src/constants/locale/key/PartyConfig'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'
import AesPartyScreeningKeys from 'src/constants/locale/key/AesPartyScreening'
import FdaPNKeys from './constants/locale/key/Fdapn'


const {
	locale: {
		key: { UiComponentKeys }
	}
} = constants

function useBreadcrumbNameMap() {
	const { translate } = useTranslation([
		Namespace.UI_COMPONENT,
		Namespace.PRODUCT_MASTER,
		Namespace.PARTY_MASTER,
		Namespace.ARRIVALMESSAGE,
		Namespace.ACI_AIR,
		Namespace.A6,
		Namespace.CONVEYANCE,
		Namespace.A6A,
		Namespace.DOC_GEN_PURCHASE_ORDER,
		Namespace.STATUS_MESSAGE,
		Namespace.H2H_SUB_FAILS,
		Namespace.FLIGHT_MATRIX,
		Namespace.VALIDATION_ERROR_LOG,
		Namespace.INVOICE,
		Namespace.H_S_CODE,
		Namespace.DBADMIN_ACI_AIR,
		Namespace.SBCI_PRODUCT_MASTER,
		Namespace.SBCI_MANIFEST,
		Namespace.SBCI_FILE_UPLOAD_HISTORY,
		Namespace.EQUIPMEMENT_MASTER,
		Namespace.CONVEYANCE_MASTER,
		Namespace.ACI_HIGHWAY_STAT_QRY,
		Namespace.CREW_MASTER,
		Namespace.ACIHWY_MANIFEST,
		Namespace.US_PRODUCT_MASTER,
		Namespace.ACI_HIGHWAY_CARGO,
		Namespace.ACI_HIGHWAY_RESPONSE,
		Namespace.ACE_HIGHWAY_SHIPMENT,
		Namespace.ACE_HIGHWAY_MANIFEST,
		Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
		Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
		Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
		Namespace.EHBL_MANIFEST,
		Namespace.EHBL_HOUSE_BILL,
		Namespace.EHBL_CLOSE_MSG,
		Namespace.ARRIVAL_CERT,
		Namespace.ACI_HW_RELEASE_NOTICE,
		Namespace.DB_ADMIN_STATUS_CONFIG,
		Namespace.FILE_USER,
		Namespace.ACI_HW_RELEASE_NOTICE,
		Namespace.US_DECLARATIONS,
		Namespace.NBCI_USCA_PARTY_MASTER,
		Namespace.FAX_TRACKER,
		Namespace.N_B_C_I_CANADA_INVOICE,
		Namespace.NBCI_US_INVOICE,
		Namespace.BILLING_CONFIG,
		Namespace.ANNOUNCEMENT,
		Namespace.SBCI_FILE_UPLOAD_HISTORY,
		Namespace.ACE_ISF,
		Namespace.ACE_OCN_BILLOFLADING,
		Namespace.ACE_OCN_MANIFEST,
		Namespace.ACE_OCN_VESSEL_MASTER,
		Namespace.ACE_OCN_VESSEL_DEPARTURE,
		Namespace.ACE_OCN_VESSEL_ARRIVAL,
		Namespace.NA_CODE_MASTER,
		Namespace.PARTY_CONFIG,
		Namespace.AES_SHIPMENT, 
		Namespace.AES_PARTY_SCREENING,
		Namespace.PARTY_CONFIG,
		Namespace.FDAPN
	])
	const translatedTextsObject = makeTranslatedTextsObject(translate)

	const breadcrumbNameMap = new Map()

	breadcrumbNameMap.set(pathMap.DASHBOARD_SUMMARY, 'Summary')

	//acihw manifest
	breadcrumbNameMap.set(
		pathMap.ACIHWY_MANIFEST_LIST_VIEW,
		translatedTextsObject.manifestListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACIHWY_MANIFEST_ADD_VIEW,
		translatedTextsObject.manifestAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACIHWY_MANIFEST_EDIT_VIEW,
		translatedTextsObject.manifestEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACIHWY_MANIFEST_DETAILS_VIEW,
		translatedTextsObject.manifestDetailsViewTitle
	)

	// dbadmin aciair
	breadcrumbNameMap.set(
		pathMap.DBADMIN_ACIAIR,
		//translatedTextsObject.advanceCommercialInformationAirAddViewTitle
		"Advance Commercial Information"
	)

	//hs code
	breadcrumbNameMap.set(
		pathMap.H_S_CODE_LIST_VIEW,
		translatedTextsObject.hSCodeListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.H_S_CODE_ADD_VIEW,
		translatedTextsObject.hSCodeAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.H_S_CODE_EDIT_VIEW,
		translatedTextsObject.hSCodeEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.H_S_CODE_DETAILS_VIEW,
		translatedTextsObject.hSCodeDetailsViewTitle
	)

	//fdex validation error
	breadcrumbNameMap.set(
		pathMap.VALIDATION_ERROR_LOG_LIST_VIEW,
		translatedTextsObject.validationErrorLogListViewTitle
	)

	//flight matrix
	breadcrumbNameMap.set(
		pathMap.FLIGHT_MATRIX_LIST_VIEW,
		translatedTextsObject.flightMatrixListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FLIGHT_MATRIX_ADD_VIEW,
		translatedTextsObject.flightMatrixAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FLIGHT_MATRIX_EDIT_VIEW,
		translatedTextsObject.flightMatrixEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FLIGHT_MATRIX_DETAILS_VIEW,
		translatedTextsObject.flightMatrixDetailsViewTitle
	)

	//product-master
	breadcrumbNameMap.set(
		pathMap.PRODUCT_MASTER_LIST_VIEW,
		translatedTextsObject.productMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PRODUCT_MASTER_ADD_VIEW,
		translatedTextsObject.productMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PRODUCT_MASTER_EDIT_VIEW,
		translatedTextsObject.productMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PRODUCT_MASTER_DETAILS_VIEW,
		translatedTextsObject.productMasterDetailsViewTitle
	)

	//us declaration
	breadcrumbNameMap.set(
		pathMap.US_DECLARATIONS_LIST_VIEW,
		translatedTextsObject.usDeclarationsListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.US_DECLARATIONS_ADD_VIEW,
		translatedTextsObject.usDeclarationsAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.US_DECLARATIONS_EDIT_VIEW,
		translatedTextsObject.usDeclarationsEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.US_DECLARATIONS_DETAILS_VIEW,
		translatedTextsObject.usDeclarationsDetailsViewTitle
	)

	//party-master
	breadcrumbNameMap.set(
		pathMap.PARTY_MASTER_LIST_VIEW,
		translatedTextsObject.partyMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PARTY_MASTER_ADD_VIEW,
		translatedTextsObject.partyMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PARTY_MASTER_EDIT_VIEW,
		translatedTextsObject.partyMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PARTY_MASTER_DETAILS_VIEW,
		translatedTextsObject.partyMasterDetailsViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PARTY_MASTER_UPLOAD_LIST_VIEW,
		'Master Data Upload'
	)

	//arrival-message
	breadcrumbNameMap.set(
		pathMap.ARRIVALMESSAGE_LIST_VIEW,
		translatedTextsObject.arrivalmessageListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ARRIVALMESSAGE_ADD_VIEW,
		translatedTextsObject.arrivalmessageAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ARRIVALMESSAGE_EDIT_VIEW,
		translatedTextsObject.arrivalmessageEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ARRIVALMESSAGE_DETAILS_VIEW,
		translatedTextsObject.arrivalmessageDetailsViewTitle
	)

	// air arrival message
	breadcrumbNameMap.set(
		pathMap.AIR_ARRIVAL_LIST_VIEW,
		translatedTextsObject.arrivalmessageListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AIR_ARRIVAL_ADD_VIEW,
		translatedTextsObject.arrivalmessageAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AIR_ARRIVAL_EDIT_VIEW,
		translatedTextsObject.arrivalmessageEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AIR_ARRIVAL_DETAILS_VIEW,
		translatedTextsObject.arrivalmessageDetailsViewTitle
	)


	//ACI-AIR arrival-message
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ARRIVAL_MESSAGE_LIST_VIEW,
		translatedTextsObject.aciairarrivalmessageListViewTitle
		//translatedTextsObject.arrivalmessageListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ARRIVAL_MESSAGE_ADD_VIEW,
		translatedTextsObject.aciairarrivalmessageAddViewTitle
		//translatedTextsObject.arrivalmessageAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ARRIVAL_MESSAGE_EDIT_VIEW,
		translatedTextsObject.aciairarrivalmessageEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ARRIVAL_MESSAGE_DETAILS_VIEW,
		translatedTextsObject.aciairarrivalmessageDetailsViewTitle
	)

	// Highway arrival message
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_ARRIVAL_LIST_VIEW,
		translatedTextsObject.arrivalmessageListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_ARRIVAL_ADD_VIEW,
		translatedTextsObject.arrivalmessageAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_ARRIVAL_EDIT_VIEW,
		translatedTextsObject.arrivalmessageEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_ARRIVAL_DETAILS_VIEW,
		translatedTextsObject.arrivalmessageDetailsViewTitle
	)

	//arrival-response
	breadcrumbNameMap.set(
		pathMap.ARRIVAL_RESPONSE_LIST_VIEW,
		translatedTextsObject.arrivalresponseListViewTitle
	)

	//ACI-AIR ACR
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ACR_LIST_VIEW,
		translatedTextsObject.aciAirACRListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ACR_ADD_VIEW,
		translatedTextsObject.aciAirACRAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ACR_EDIT_VIEW,
		translatedTextsObject.aciAirACREditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_ACR_DETAILS_VIEW,
		translatedTextsObject.aciAirACRDetailsViewTitle
	)

	//ACI-AIR SSR
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_SSR_LIST_VIEW,
		translatedTextsObject.aciAirSSRListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_SSR_ADD_VIEW,
		translatedTextsObject.aciAirSSRAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_SSR_EDIT_VIEW,
		translatedTextsObject.aciAirSSREditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_SSR_DETAILS_VIEW,
		translatedTextsObject.aciAirSSRDetailsViewTitle
	)

	//ACI-AIR S10
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_S10_LIST_VIEW,
		translatedTextsObject.aciAirS10ListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_S10_ADD_VIEW,
		translatedTextsObject.aciAirS10AddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_S10_EDIT_VIEW,
		translatedTextsObject.aciAirS10EditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_AIR_S10_DETAILS_VIEW,
		translatedTextsObject.aciAirS10DetailsViewTitle
	)

	//aci-ocean a6
	breadcrumbNameMap.set(
		pathMap.ACIOCEAN_LIST_VIEW,
		translatedTextsObject.acioceanListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACIOCEAN_ADD_VIEW,
		translatedTextsObject.acioceanAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACIOCEAN_EDIT_VIEW,
		translatedTextsObject.acioceanEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACIOCEAN_DETAILS_VIEW,
		translatedTextsObject.acioceanDetailsViewTitle
	)

	//arrival-message
	breadcrumbNameMap.set(
		pathMap.ACI_OCN_ARRIVAL_MESSAGE_LIST_VIEW,
		translatedTextsObject.acioceanarrivalmessageListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_OCN_ARRIVAL_MESSAGE_ADD_VIEW,
		translatedTextsObject.acioceanarrivalmessageAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_OCN_ARRIVAL_MESSAGE_EDIT_VIEW,
		translatedTextsObject.acioceanarrivalmessageEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_OCN_ARRIVAL_MESSAGE_DETAILS_VIEW,
		translatedTextsObject.acioceanarrivalmessageDetailsViewTitle
	)

	//aci-air conveyance 
	breadcrumbNameMap.set(
		pathMap.CONVEYANCE_LIST_VIEW,
		translatedTextsObject.conveyanceListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CONVEYANCE_ADD_VIEW,
		translatedTextsObject.conveyanceAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CONVEYANCE_EDIT_VIEW,
		translatedTextsObject.conveyanceEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CONVEYANCE_DETAILS_VIEW,
		translatedTextsObject.conveyanceDetailsViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.A6A_LIST_VIEW,
		translatedTextsObject.a6aListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.A6A_ADD_VIEW,
		translatedTextsObject.a6aAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.A6A_EDIT_VIEW,
		translatedTextsObject.a6aEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.A6A_DETAILS_VIEW,
		translatedTextsObject.a6aDetailsViewTitle
	)

	//doc gen purchase order
	breadcrumbNameMap.set(
		pathMap.DOC_GEN_PURCHASE_ORDER_LIST_VIEW,
		translatedTextsObject.docGenPurchaseOrderListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.DOC_GEN_PURCHASE_ORDER_ADD_VIEW,
		translatedTextsObject.docGenPurchaseOrderAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.DOC_GEN_PURCHASE_ORDER_EDIT_VIEW,
		translatedTextsObject.docGenPurchaseOrderEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.DOC_GEN_PURCHASE_ORDER_DETAILS_VIEW,
		translatedTextsObject.docGenPurchaseOrderDetailsViewTitle
	)

	//status message
	breadcrumbNameMap.set(
		pathMap.STATUS_MESSAGE_LIST_VIEW,
		translatedTextsObject.statusMessageListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.STATUS_MESSAGE_ADD_VIEW,
		translatedTextsObject.statusMessageAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.STATUS_MESSAGE_EDIT_VIEW,
		translatedTextsObject.statusMessageEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.STATUS_MESSAGE_DETAILS_VIEW,
		translatedTextsObject.statusMessageDetailsViewTitle
	)

	//h2h sub fails
	breadcrumbNameMap.set(
		pathMap.H2H_SUB_FAILS_LIST_VIEW,
		translatedTextsObject.h2hSubFailsListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.H2H_SUB_FAILS_ADD_VIEW,
		translatedTextsObject.h2hSubFailsAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.H2H_SUB_FAILS_EDIT_VIEW,
		translatedTextsObject.h2hSubFailsEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.H2H_SUB_FAILS_DETAILS_VIEW,
		translatedTextsObject.h2hSubFailsDetailsViewTitle
	)

	//Doc Gen invoice
	breadcrumbNameMap.set(
		pathMap.INVOICE_LIST_VIEW,
		translatedTextsObject.invoiceListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.INVOICE_ADD_VIEW,
		translatedTextsObject.invoiceAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.INVOICE_EDIT_VIEW,
		translatedTextsObject.invoiceEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.INVOICE_DETAILS_VIEW,
		translatedTextsObject.invoiceDetailsViewTitle
	)

	//SBCI invoice
	breadcrumbNameMap.set(
		pathMap.SBCI_INVOICE_LIST_VIEW,
		translatedTextsObject.sbciInvoiceListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_INVOICE_ADD_VIEW,
		translatedTextsObject.sbciInvoiceAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_INVOICE_EDIT_VIEW,
		translatedTextsObject.sbciInvoiceEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_INVOICE_DETAILS_VIEW,
		translatedTextsObject.sbciInvoiceDetailsViewTitle
	)

	//SBCI Product Master
	breadcrumbNameMap.set(
		pathMap.SBCI_PRODUCT_MASTER_LIST_VIEW,
		translatedTextsObject.sbciProductMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_PRODUCT_MASTER_ADD_VIEW,
		translatedTextsObject.sbciProductMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_PRODUCT_MASTER_EDIT_VIEW,
		translatedTextsObject.sbciProductMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_PRODUCT_MASTER_DETAILS_VIEW,
		translatedTextsObject.sbciProductMasterDetailsViewTitle
	)

	//SBCI Manifest
	breadcrumbNameMap.set(
		pathMap.SBCI_MANIFEST_LIST_VIEW,
		translatedTextsObject.sbciManifestListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_MANIFEST_ADD_VIEW,
		translatedTextsObject.sbciManifestAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_MANIFEST_EDIT_VIEW,
		translatedTextsObject.sbciManifestEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.SBCI_MANIFEST_DETAILS_VIEW,
		translatedTextsObject.sbciManifestDetailsViewTitle
	)

	//SBCI File Upload History
	breadcrumbNameMap.set(
		pathMap.SBCI_FILE_UPLOAD_HISTORY_LIST_VIEW,
		translatedTextsObject.sbciFileUploadTitle
	)

	//ACIHW Equipment Master
	breadcrumbNameMap.set(
		pathMap.EQUIPMENT_MASTER_LIST_VIEW,
		translatedTextsObject.equipmentMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EQUIPMENT_MASTER_ADD_VIEW,
		translatedTextsObject.equipmentMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EQUIPMENT_MASTER_EDIT_VIEW,
		translatedTextsObject.equipmentMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EQUIPMENT_MASTER_DETAILS_VIEW,
		translatedTextsObject.equipmentMasterDetailsViewTitle
	)

	//ACIHW Conveyance Master
	breadcrumbNameMap.set(
		pathMap.CONV_MAST_LIST_VIEW,
		translatedTextsObject.conveyanceListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CONV_MAST_ADD_VIEW,
		translatedTextsObject.conveyanceAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CONV_MAST_EDIT_VIEW,
		translatedTextsObject.conveyanceEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CONV_MAST_DETAILS_VIEW,
		translatedTextsObject.conveyanceDetailsViewTitle
	)

	//ACIHW Status Query
	breadcrumbNameMap.set(
		pathMap.ACI_HW_STAT_QRY_LIST_VIEW,
		translatedTextsObject.aciHighwayStatusQueryListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_STAT_QRY_ADD_VIEW,
		translatedTextsObject.aciHighwayStatusQueryAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_STAT_QRY_EDIT_VIEW,
		translatedTextsObject.aciHighwayStatusQueryEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_STAT_QRY_DETAILS_VIEW,
		translatedTextsObject.aciHighwayStatusQueryDetailsViewTitle
	)

	// ACI Highway Crew Master
	breadcrumbNameMap.set(
		pathMap.CREW_MASTER_PATH,
		translatedTextsObject.crewMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CREW_MASTER_ADD_VIEW,
		translatedTextsObject.crewMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CREW_MASTER_EDIT_VIEW,
		translatedTextsObject.crewMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CREW_MASTER_DETAILS_VIEW,
		translatedTextsObject.crewMasterDetailsViewTitle
	)

	//ACIHW Cargo
	breadcrumbNameMap.set(
		pathMap.ACI_HW_CARGO_LIST_VIEW,
		translatedTextsObject.aciHighwayCargoListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_CARGO_ADD_VIEW,
		translatedTextsObject.aciHighwayCargoAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_CARGO_EDIT_VIEW,
		translatedTextsObject.aciHighwayCargoEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_CARGO_DETAILS_VIEW,
		translatedTextsObject.aciHighwayCargoDetailsViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_D4_NOTICE,
		translatedTextsObject.aciHighwayD4NoticeListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_D4_NOTICE,
		translatedTextsObject.aciHighwayD4NoticeListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.WACM_D4_NOTICE,
		translatedTextsObject.aciHighwayD4NoticeListViewTitle
	)

	//ACI HW Release Notice
	breadcrumbNameMap.set(
		pathMap.ACI_HW_RELEASE_NOTICE_LIST_VIEW,
		translatedTextsObject.aciHwReleaseNoticeListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_RELEASE_NOTICE_ADD_VIEW,
		translatedTextsObject.aciHwReleaseNoticeAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_RELEASE_NOTICE_EDIT_VIEW,
		translatedTextsObject.aciHwReleaseNoticeEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HW_RELEASE_NOTICE_DETAILS_VIEW,
		translatedTextsObject.aciHwReleaseNoticeDetailsViewTitle
	)

	//US Product Master
	breadcrumbNameMap.set(
		pathMap.US_PRODUCT_MASTER_LIST_VIEW,
		translatedTextsObject.usProductMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.US_PRODUCT_MASTER_ADD_VIEW,
		translatedTextsObject.usProductMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.US_PRODUCT_MASTER_EDIT_VIEW,
		translatedTextsObject.usProductMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.US_PRODUCT_MASTER_DETAILS_VIEW,
		translatedTextsObject.usProductMasterDetailsViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.US_PRODUCT_MASTER_DETAILS_UPLOAD,
		translatedTextsObject.usProductMasterUploadTitle
	)

	//ACE Highway
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW,
		translatedTextsObject.aceHighwayListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW,
		translatedTextsObject.aceHighwayAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_SHIPMENT_EDIT_VIEW,
		translatedTextsObject.aceHighwayEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_SHIPMENT_DETAILS_VIEW,
		translatedTextsObject.aceHighwayDetailsViewTitle
	)

	//ACE Highway Manifest
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW,
		translatedTextsObject.aceHighwayManifestListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_MANIFEST_ADD_VIEW,
		translatedTextsObject.aceHighwayManifestAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_MANIFEST_EDIT_VIEW,
		translatedTextsObject.aceHighwayManifestEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_MANIFEST_DETAILS_VIEW,
		translatedTextsObject.aceHighwayManifestDetailsViewTitle
	)

	// ACE HIGHWAY INBOND ARRIVAL
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW,
		translatedTextsObject.aceHwyInbondArrivalListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW,
		translatedTextsObject.aceHwyInbondArrivalAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_EDIT_VIEW,
		translatedTextsObject.aceHwyInbondArrivalEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_DETAILS_VIEW,
		translatedTextsObject.aceHwyInbondArrivalDetailsViewTitle
	)

	//ABI Inbond 7512
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_7512_LIST_VIEW,
		translatedTextsObject.abiInbond7512ListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_7512_ADD_VIEW,
		translatedTextsObject.abiInbond7512AddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_7512_EDIT_VIEW,
		translatedTextsObject.abiInbond7512EditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_7512_DETAILS_VIEW,
		translatedTextsObject.abiInbond7512DetailsViewTitle
	)

	//ABI Inbond Event
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_EVENT_LIST_VIEW,
		translatedTextsObject.abiInbondEventListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_EVENT_ADD_VIEW,
		translatedTextsObject.abiInbondEventAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_EVENT_EDIT_VIEW,
		translatedTextsObject.abiInbondEventEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ABI_INBOND_EVENT_DETAILS_VIEW,
		translatedTextsObject.abiInbondEventDetailsViewTitle
	)

	// ACI Highway Inbond Arrival
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW,
		translatedTextsObject.arrivalCertListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW,
		translatedTextsObject.arrivalCertAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_EDIT_VIEW,
		translatedTextsObject.arrivalCertEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_DETAILS_VIEW,
		translatedTextsObject.arrivalCertDetailsViewTitle
	)

	//EHBL Manifest
	breadcrumbNameMap.set(
		pathMap.EHBL_MANIFEST_LIST_VIEW,
		translatedTextsObject.ehblManifestListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_MANIFEST_ADD_VIEW,
		translatedTextsObject.ehblManifestAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_MANIFEST_EDIT_VIEW,
		translatedTextsObject.ehblManifestEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_MANIFEST_DETAILS_VIEW,
		translatedTextsObject.ehblManifestDetailsViewTitle
	)

	//EHBL House Bill
	breadcrumbNameMap.set(
		pathMap.EHBL_HOUSE_BILL_LIST_VIEW,
		translatedTextsObject.ehblHouseBillListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_HOUSE_BILL_ADD_VIEW,
		translatedTextsObject.ehblHouseBillAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_HOUSE_BILL_EDIT_VIEW,
		translatedTextsObject.ehblHouseBillEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_HOUSE_BILL_DETAILS_VIEW,
		translatedTextsObject.ehblHouseBillDetailsViewTitle
	)

	//EHBL Close Message
	breadcrumbNameMap.set(
		pathMap.EHBL_CLOSE_MSG_LIST_VIEW,
		translatedTextsObject.ehblCloseMsgListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_CLOSE_MSG_ADD_VIEW,
		translatedTextsObject.ehblCloseMsgAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_CLOSE_MSG_EDIT_VIEW,
		translatedTextsObject.ehblCloseMsgEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.EHBL_CLOSE_MSG_DETAILS_VIEW,
		translatedTextsObject.ehblCloseMsgDetailsViewTitle
	)

	//EHBL Manifest Forward
	breadcrumbNameMap.set(
		pathMap.EHBL_MANIFEST_FORWARD_LIST_VIEW,
		translatedTextsObject.ehblManifestForwardListViewTitle
	)

	//EHBL Manifest Forward
	breadcrumbNameMap.set(
		pathMap.EHBL_MANIFEST_FORWARD_DETAILS_VIEW,
		translatedTextsObject.ehblHouseBillDetailsViewTitle
	)


	//DB Admin
	breadcrumbNameMap.set(
		pathMap.DB_ADMIN_STATUS_CONFIG_LIST_VIEW,
		translatedTextsObject.dbAdminStatusConfigListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.DB_ADMIN_STATUS_CONFIG_ADD_VIEW,
		translatedTextsObject.dbAdminStatusConfigAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.DB_ADMIN_STATUS_CONFIG_EDIT_VIEW,
		translatedTextsObject.dbAdminStatusConfigEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.DB_ADMIN_STATUS_CONFIG_DETAILS_VIEW,
		translatedTextsObject.dbAdminStatusConfigDetailsViewTitle
	)

	breadcrumbNameMap.set(
		pathMap.FILE_FOR_USER_LIST_VIEW,
		"File for User"
	)

	//NBCI US CA PARTY Master
	breadcrumbNameMap.set(
		pathMap.NBCI_USCA_PARTY_MASTER_LIST_VIEW,
		translatedTextsObject.nbciPartyMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.NBCI_USCA_PARTY_MASTER_ADD_VIEW,
		translatedTextsObject.nbciPartyMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.NBCI_USCA_PARTY_MASTER_EDIT_VIEW,
		translatedTextsObject.nbciPartyMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.NBCI_USCA_PARTY_MASTER_DETAILS_VIEW,
		translatedTextsObject.nbciPartyMasterDetailsViewTitle
	)

	//Manifest Fax Tracker
	breadcrumbNameMap.set(
		pathMap.MAN_FAX_TRAC_LIST_VIEW,
		translatedTextsObject.faxTrackerListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.MAN_FAX_TRAC_ADD_VIEW,
		translatedTextsObject.faxTrackerAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.MAN_FAX_TRAC_EDIT_VIEW,
		translatedTextsObject.faxTrackerEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.MAN_FAX_TRAC_DETAILS_VIEW,
		translatedTextsObject.faxTrackerDetailsViewTitle
	)

	//Billing Config
	breadcrumbNameMap.set(
		pathMap.BILLING_CONFIG_LIST_VIEW,
		translatedTextsObject.billingConfigListViewTitle
	)

	// NBCI Canada Invoice		
	breadcrumbNameMap.set(
		pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW,
		translatedTextsObject.nBCICanadaInvoiceListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.N_B_C_I_CANADA_INVOICE_ADD_VIEW,
		translatedTextsObject.nBCICanadaInvoiceAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.N_B_C_I_CANADA_INVOICE_EDIT_VIEW,
		translatedTextsObject.nBCICanadaInvoiceEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.N_B_C_I_CANADA_INVOICE_DETAILS_VIEW,
		translatedTextsObject.nBCICanadaInvoiceDetailsViewTitle
	)

	//NBCI US Invoice
	breadcrumbNameMap.set(
		pathMap.NBCI_US_INVOICE_LIST_VIEW,
		translatedTextsObject.nbciUsInvoiceListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.NBCI_US_INVOICE_ADD_VIEW,
		translatedTextsObject.nbciUsInvoiceAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.NBCI_US_INVOICE_EDIT_VIEW,
		translatedTextsObject.nbciUsInvoiceEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.NBCI_US_INVOICE_DETAILS_VIEW,
		translatedTextsObject.nbciUsInvoiceDetailsViewTitle
	)

	breadcrumbNameMap.set(
		pathMap.NBCI_UPLOAD,
		translatedTextsObject.nbciUploadTitle
	)

	//Annoucement
	breadcrumbNameMap.set(
		pathMap.ANNOUNCEMENT_LIST_VIEW,
		translatedTextsObject.announcementListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ANNOUNCEMENT_ADD_VIEW,
		translatedTextsObject.announcementAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ANNOUNCEMENT_EDIT_VIEW,
		translatedTextsObject.announcementEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ANNOUNCEMENT_DETAILS_VIEW,
		translatedTextsObject.announcementDetailsViewTitle
	)

	breadcrumbNameMap.set(
		pathMap.USER_PROFILE_LIST_VIEW,
		'User Settings'
	)

	//ACE ISF 10
	breadcrumbNameMap.set(
		pathMap.ACEISF10_LIST_VIEW,
		translatedTextsObject.aceIsf10ListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACEISF10_ADD_VIEW,
		translatedTextsObject.aceIsf10AddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACEISF10_EDIT_VIEW,
		translatedTextsObject.aceIsf10EditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACEISF10_DETAILS_VIEW,
		translatedTextsObject.aceIsf10DetailsViewTitle
	)

	//ACE ISF 5
	breadcrumbNameMap.set(
		pathMap.ACEISF5_LIST_VIEW,
		translatedTextsObject.aceIsf5ListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACEISF5_ADD_VIEW,
		translatedTextsObject.aceIsf5AddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACEISF5_EDIT_VIEW,
		translatedTextsObject.aceIsf5EditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACEISF5_DETAILS_VIEW,
		translatedTextsObject.aceIsf5DetailsViewTitle
	)
	
	//ACE_OCEAN
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_BILLOFLADING_LIST_VIEW,
		translatedTextsObject.aceOcnBillofladingListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_BILLOFLADING_ADD_VIEW,
		translatedTextsObject.aceOcnBillofladingAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_BILLOFLADING_EDIT_VIEW,
		translatedTextsObject.aceOcnBillofladingEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_BILLOFLADING_DETAILS_VIEW,
		translatedTextsObject.aceOcnBillofladingDetailsViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_MANIFEST_LIST_VIEW,
		translatedTextsObject.aceOcnManifestListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_MANIFEST_ADD_VIEW,
		translatedTextsObject.aceOcnManifestAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_MANIFEST_EDIT_VIEW,
		translatedTextsObject.aceOcnManifestEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_MANIFEST_DETAILS_VIEW,
		translatedTextsObject.aceOcnManifestDetailsViewTitle
	)

	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_MASTER_LIST_VIEW,
		translatedTextsObject.vesselMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_MASTER_ADD_VIEW,
		translatedTextsObject.vesselMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_MASTER_EDIT_VIEW,
		translatedTextsObject.vesselMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_MASTER_DETAILS_VIEW,
		translatedTextsObject.vesselMasterDetailsViewTitle
	)

	//Vessel Arrival
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_ARRIVAL_LIST_VIEW,
		translatedTextsObject.aceOcnVesselArrivalListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_ARRIVAL_ADD_VIEW,
		translatedTextsObject.aceOcnVesselArrivalAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_ARRIVAL_EDIT_VIEW,
		translatedTextsObject.aceOcnVesselArrivalEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_ARRIVAL_DETAILS_VIEW,
		translatedTextsObject.aceOcnVesselArrivalDetailsViewTitle
	)

	//Vessel Departure
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_DEPARTURE_LIST_VIEW,
		translatedTextsObject.aceOcnVesselDepartureListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_DEPARTURE_ADD_VIEW,
		translatedTextsObject.aceOcnVesselDepartureAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_DEPARTURE_EDIT_VIEW,
		translatedTextsObject.aceOcnVesselDepartureEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.ACE_OCN_VESSEL_DEPARTURE_DETAILS_VIEW,
		translatedTextsObject.aceOcnVesselDepartureDetailsViewTitle
	)

    //party config
    breadcrumbNameMap.set(
		pathMap.PARTY_CONFIG_LIST_VIEW,
		translatedTextsObject.partyConfigListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PARTY_CONFIG_ADD_VIEW,
		translatedTextsObject.partyConfigAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PARTY_CONFIG_EDIT_VIEW,
		translatedTextsObject.partyConfigEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.PARTY_CONFIG_DETAILS_VIEW,
		translatedTextsObject.partyConfigDetailsViewTitle
	)

	//aes shipment
	breadcrumbNameMap.set(
		pathMap.AES_SHIPMENT_LIST_VIEW,
		translatedTextsObject.aesShipmentListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_SHIPMENT_ADD_VIEW,
		translatedTextsObject.aesShipmentAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_SHIPMENT_EDIT_VIEW,
		translatedTextsObject.aesShipmentEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_SHIPMENT_DETAILS_VIEW,
		translatedTextsObject.aesShipmentDetailsViewTitle
	)

	//aes party screening
	breadcrumbNameMap.set(
		pathMap.AES_PARTY_SCREENING_LIST_VIEW,
		translatedTextsObject.aesPartyScreeningListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_PARTY_SCREENING_ADD_VIEW,
		translatedTextsObject.aesPartyScreeningAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_PARTY_SCREENING_EDIT_VIEW,
		translatedTextsObject.aesPartyScreeningEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_PARTY_SCREENING_DETAILS_VIEW,
		translatedTextsObject.aesPartyScreeningDetailsViewTitle
	)

	//aes product master
	breadcrumbNameMap.set(
		pathMap.AES_PRODUCT_MASTER_LIST_VIEW,
		translatedTextsObject.aesProductMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_PRODUCT_MASTER_ADD_VIEW,
		translatedTextsObject.aesProductMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_PRODUCT_MASTER_EDIT_VIEW,
		translatedTextsObject.aesProductMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.AES_PRODUCT_MASTER_DETAILS_VIEW,
		translatedTextsObject.aesProductMasterDetailsViewTitle
	)


	//FDA PN
	breadcrumbNameMap.set(
		pathMap.FDAPN_LIST_VIEW,
		translatedTextsObject.fdapnListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FDAPN_ADD_VIEW,
		translatedTextsObject.fdapnAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FDAPN_EDIT_VIEW,
		translatedTextsObject.fdapnEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FDAPN_DETAILS_VIEW,
		translatedTextsObject.fdapnDetailsViewTitle
	)

	//FDA PN Product Master
	breadcrumbNameMap.set(
		pathMap.FDAPN_PRODUCTMASTER_LIST_VIEW,
		translatedTextsObject.fdapnProductMasterListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FDAPN_PRODUCTMASTER_ADD_VIEW,
		translatedTextsObject.fdapnProductMasterAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FDAPN_PRODUCTMASTER_EDIT_VIEW,
		translatedTextsObject.fdapnProductMasterEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.FDAPN_PRODUCTMASTER_DETAILS_VIEW,
		translatedTextsObject.fdapnProductMasterDetailsViewTitle
	)

	return breadcrumbNameMap
}

function makeTranslatedTextsObject(translate) {

	// dbadmin aciair
	let advanceCommercialInformationAir = translate(
		Namespace.DBADMIN_ACI_AIR,
		DbadminAciAirKeys.TITLE
	)
	let advanceCommercialInformationAirListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: advanceCommercialInformationAir
		}
	)
	let advanceCommercialInformationAirAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: advanceCommercialInformationAir
		}
	)

	//hscode
	let hSCode = translate(
		Namespace.H_S_CODE,
		HSCodeKeys.TITLE
	)
	let hSCodeListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: hSCode
		}
	)
	let hSCodeAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: hSCode
		}
	)
	let hSCodeEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: hSCode
		}
	)
	let hSCodeDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: hSCode
		}
	)

	//fdex validation error 
	let validationErrorLog = translate(
		Namespace.VALIDATION_ERROR_LOG,
		ValidationErrorLogKeys.TITLE
	)
	let validationErrorLogListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: validationErrorLog
		}
	)

	//flight matrix
	let flightMatrix = translate(
		Namespace.FLIGHT_MATRIX,
		FlightMatrixKeys.TITLE
	)
	let flightMatrixListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: flightMatrix
		}
	)
	let flightMatrixAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: flightMatrix
		}
	)
	let flightMatrixEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: flightMatrix
		}
	)
	let flightMatrixDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: flightMatrix
		}
	)

	let productMaster = translate(
		Namespace.PRODUCT_MASTER,
		ProductMasterKeys.TITLE
	)
	let productMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: productMaster
		}
	)
	let productMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: productMaster
		}
	)
	let productMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: productMaster
		}
	)
	let productMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: productMaster
		}
	)

	//party-master
	let partyMaster = translate(
		Namespace.PARTY_MASTER,
		PartyMasterKeys.TITLE
	)
	let partyMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: partyMaster
		}
	)
	let partyMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: partyMaster
		}
	)
	let partyMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: partyMaster
		}
	)
	let partyMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: partyMaster
		}
	)
	let partyMasterDetailsUploadTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.UPLOAD_PAGE_TITLE,
		{ title: partyMaster }
	)

	//arrival-message
	let arrivalmessage = translate(
		Namespace.ARRIVALMESSAGE,
		ArrivalmessageKeys.TITLE
	)
	let arrivalmessageListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)
	let arrivalmessageAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)
	let arrivalmessageEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)
	let arrivalmessageDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)

	//arrival-response
	let arrivalresponse = translate(
		Namespace.ARRIVALMESSAGE,
		ArrivalmessageKeys.TITLE
	)
	let arrivalresponseListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: arrivalresponse
		}
	)

	//ACI-AIR ACR
	let aciAirACR = translate(
		Namespace.ACI_AIR,
		AciAirKeys.ACR_TITLE
	)
	let aciAirACRListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aciAirACR
		}
	)
	let aciAirACRAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aciAirACR
		}
	)
	let aciAirACREditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aciAirACR
		}
	)
	let aciAirACRDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aciAirACR
		}
	)

	//ACI-AIR SSR
	let aciAirSSR = translate(
		Namespace.ACI_AIR,
		AciAirKeys.SSR_TITLE
	)
	let aciAirSSRListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aciAirSSR
		}
	)
	let aciAirSSRAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aciAirSSR
		}
	)
	let aciAirSSREditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aciAirSSR
		}
	)
	let aciAirSSRDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aciAirSSR
		}
	)

	//ACI-AIR S10
	let aciAirS10 = translate(
		Namespace.ACI_AIR,
		AciAirKeys.S10_TITLE
	)
	let aciAirS10ListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aciAirS10
		}
	)
	let aciAirS10AddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aciAirS10
		}
	)
	let aciAirS10EditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aciAirS10
		}
	)
	let aciAirS10DetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aciAirS10
		}
	)
   //ACI-AIR arrival-message
   let aciAirArrivalMessage = translate(
	Namespace.ACI_AIR,
	AciAirKeys.ACI_AIR_ARRIVAL_MESSAGE_TITLE
)
  let aciairarrivalmessageListViewTitle = translate(
	Namespace.UI_COMPONENT,
	UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
	{
		title: arrivalmessage
	}
)
let aciairarrivalmessageAddViewTitle = translate(
	Namespace.UI_COMPONENT,
	UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
	{
		title: arrivalmessage
	}
)
let aciairarrivalmessageEditViewTitle = translate(
	Namespace.UI_COMPONENT,
	UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
	{
		title: arrivalmessage
	}
)
let aciairarrivalmessageDetailsViewTitle = translate(
	Namespace.UI_COMPONENT,
	UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
	{
		title: arrivalmessage
	}
)

	//a6 (aci-ocean)
	let aciocean = translate(
		Namespace.A6,
		AcioceanKeys.TITLE
	)
	let acioceanListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aciocean
		}
	)
	let acioceanAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aciocean
		}
	)
	let acioceanEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aciocean
		}
	)
	let acioceanDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aciocean
		}
	)

	//a6a
	let a6a = translate(
		Namespace.A6A,
		A6aKeys.TITLE
	)
	let a6aListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: a6a
		}
	)
	let a6aAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: a6a
		}
	)
	let a6aEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: a6a
		}
	)
	let a6aDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: a6a
		}
	)

	//aci ocean arrival-message
	let acioceanarrivalmessageListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)
	let acioceanarrivalmessageAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)
	let acioceanarrivalmessageEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)
	let acioceanarrivalmessageDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: arrivalmessage
		}
	)

	//conveyance aci-air
	let conveyance = translate(
		Namespace.CONVEYANCE,
		ConveyanceKeys.TITLE
	)
	let conveyanceListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: conveyance
		}
	)
	let conveyanceAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: conveyance
		}
	)
	let conveyanceEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: conveyance
		}
	)
	let conveyanceDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: conveyance
		}
	)

	let docGenPurchaseOrder = translate(
		Namespace.DOC_GEN_PURCHASE_ORDER,
		DocGenPurchaseOrderKeys.TITLE
	)
	let docGenPurchaseOrderListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: docGenPurchaseOrder
		}
	)
	let docGenPurchaseOrderAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: docGenPurchaseOrder
		}
	)
	let docGenPurchaseOrderEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: docGenPurchaseOrder
		}
	)
	let docGenPurchaseOrderDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: docGenPurchaseOrder
		}
	)

	let statusMessage = translate(
		Namespace.STATUS_MESSAGE,
		StatusMessageKeys.TITLE
	)
	let statusMessageListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: statusMessage
		}
	)
	let statusMessageAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: statusMessage
		}
	)
	let statusMessageEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: statusMessage
		}
	)
	let statusMessageDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: statusMessage
		}
	)

	//h2h sub fails
	let h2hSubFails = translate(
		Namespace.H2H_SUB_FAILS,
		H2hSubFailsKeys.TITLE
	)
	let h2hSubFailsListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: h2hSubFails
		}
	)
	let h2hSubFailsAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: h2hSubFails
		}
	)
	let h2hSubFailsEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: h2hSubFails
		}
	)
	let h2hSubFailsDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: h2hSubFails
		}
	)

	//invoice
	let invoice = translate(
		Namespace.INVOICE,
		InvoiceKeys.TITLE
	)
	let invoiceListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: invoice
		}
	)
	let invoiceAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: invoice
		}
	)
	let invoiceEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: invoice
		}
	)
	let invoiceDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: invoice
		}
	)

	let sbciInvoice = translate(
		Namespace.SBCI_INVOICE,
		SbciInvoiceKeys.TITLE
	)
	let sbciInvoiceListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: sbciInvoice
		}
	)
	let sbciInvoiceAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: sbciInvoice
		}
	)
	let sbciInvoiceEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: sbciInvoice
		}
	)
	let sbciInvoiceDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: sbciInvoice
		}
	)

	let sbciProductMaster = translate(
		Namespace.SBCI_PRODUCT_MASTER,
		SbciProductMasterKeys.TITLE
	)
	let sbciProductMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: sbciProductMaster
		}
	)
	let sbciProductMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: sbciProductMaster
		}
	)
	let sbciProductMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: sbciProductMaster
		}
	)
	let sbciProductMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: sbciProductMaster
		}
	)

	//Sbci Manifest
	let sbciManifest = translate(
		Namespace.SBCI_MANIFEST,
		SbciManifestKeys.TITLE
	)
	let sbciManifestListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: sbciManifest
		}
	)
	let sbciManifestAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: sbciManifest
		}
	)
	let sbciManifestEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: sbciManifest
		}
	)
	let sbciManifestDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: sbciManifest
		}
	)

	//SBCI File Upload History
	let sbciFileUploadTitle = translate(
		Namespace.SBCI_FILE_UPLOAD_HISTORY,
		SbciFileUploadHistoryKeys.TITLE
	)

	//ACIHWY Equipment Master
	let equipmentMaster = translate(
		Namespace.EQUIPMEMENT_MASTER,
		EquipmentMasterKeys.TITLE
	)
	let equipmentMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: equipmentMaster
		}
	)
	let equipmentMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: equipmentMaster
		}
	)
	let equipmentMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: equipmentMaster
		}
	)
	let equipmentMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: equipmentMaster
		}
	)

	//ACIHW CONVEYANCE Master
	let conveyanceMaster = translate(
		Namespace.CONVEYANCE_MASTER,
		ConveyanceMasterKeys.TITLE
	)
	let conveyanceMastListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: conveyanceMaster
		}
	)
	let conveyanceMastAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: conveyanceMaster
		}
	)
	let conveyanceMastEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: conveyanceMaster
		}
	)
	let conveyanceMastDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: conveyanceMaster
		}
	)

	//ACIHW Status Query
	let AciHighwayStatusQuery = translate(
		Namespace.ACI_HIGHWAY_STAT_QRY,
		AciHighwayStatQryKeys.TITLE
	)
	let aciHighwayStatusQueryListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: AciHighwayStatusQuery
		}
	)
	let aciHighwayStatusQueryAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: AciHighwayStatusQuery
		}
	)
	let aciHighwayStatusQueryEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: AciHighwayStatusQuery
		}
	)
	let aciHighwayStatusQueryDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: AciHighwayStatusQuery
		}
	)
	// ACI Highway Crew Master
	let crewMaster = translate(
		Namespace.CREW_MASTER,
		CrewMasterKeys.TITLE
	)
	let crewMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: crewMaster
		}
	)
	let crewMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: crewMaster
		}
	)
	let crewMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: crewMaster
		}
	)
	let crewMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: crewMaster
		}
	)

	let usProductMaster = translate(
		Namespace.US_PRODUCT_MASTER,
		UsProductMasterKeys.TITLE
	)
	let usProductMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: usProductMaster
		}
	)
	let usProductMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: usProductMaster
		}
	)
	let usProductMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: usProductMaster
		}
	)
	let usProductMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: usProductMaster
		}
	)

	//Us declaration
	let usDeclarations = translate(
		Namespace.US_DECLARATIONS,
		UsDeclarationsKeys.TITLE
	)
	let usDeclarationsListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: usDeclarations
		}
	)
	let usDeclarationsAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: usDeclarations
		}
	)
	let usDeclarationsEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: usDeclarations
		}
	)
	let usDeclarationsDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: usDeclarations
		}
	)

	//ACIHW Cargo
	let AciHighwayCargo = translate(
		Namespace.ACI_HIGHWAY_CARGO,
		AciHighwayCargoKeys.TITLE
	)
	let aciHighwayCargoListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: AciHighwayCargo
		}
	)
	let aciHighwayCargoAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: AciHighwayCargo
		}
	)
	let aciHighwayCargoEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: AciHighwayCargo
		}
	)
	let aciHighwayCargoDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: AciHighwayCargo
		}
	)

	// acihw manifest
	let manifest = translate(
		Namespace.ACIHWY_MANIFEST,
		ManifestKeys.TITLE
	)
	let manifestListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: manifest
		}
	)
	let manifestAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: manifest
		}
	)
	let manifestEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: manifest
		}
	)
	let manifestDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: manifest
		}
	)
	let aciHighwayD4Notice = translate(
		Namespace.ACI_HIGHWAY_RESPONSE,
		AciHighwayResponseKeys.D4_NOTICE_TITLE
	)
	let aciHighwayD4NoticeListViewTitle = translate(
		Namespace.ACI_HIGHWAY_RESPONSE,
		AciHighwayResponseKeys.D4_NOTICE_TITLE
	)
	let aciHighwayReleaseNotice = translate(
		Namespace.ACI_HIGHWAY_RESPONSE,
		AciHighwayResponseKeys.RELEASE_NOTICE_TITLE
	)
	let aciHighwayReleaseNoticeListViewTitle = translate(
		Namespace.ACI_HIGHWAY_RESPONSE,
		AciHighwayResponseKeys.RELEASE_NOTICE_TITLE
	)

	//ACE Highway
	let aceHighway = translate(
		Namespace.ACE_HIGHWAY_SHIPMENT,
		AceHighwayShipmentKeys.TITLE
	)
	let aceHighwayListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aceHighway
		}
	)
	let aceHighwayAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aceHighway
		}
	)
	let aceHighwayEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aceHighway
		}
	)
	let aceHighwayDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aceHighway
		}
	)

	//ACE Highway Manifest
	let aceHighwayManifest = translate(
		Namespace.ACE_HIGHWAY_MANIFEST,
		AceHighwayManifestKeys.TITLE
	)
	let aceHighwayManifestListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aceHighwayManifest
		}
	)
	let aceHighwayManifestAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aceHighwayManifest
		}
	)
	let aceHighwayManifestEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aceHighwayManifest
		}
	)
	let aceHighwayManifestDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aceHighwayManifest
		}
	)

	//ACE HIGHWAY INBOND ARRIVAL
	let aceHwyInbondArrival = translate(
		Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
		AceHwyInbondArrivalKeys.TITLE
	)
	let aceHwyInbondArrivalListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aceHwyInbondArrival
		}
	)
	let aceHwyInbondArrivalAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aceHwyInbondArrival
		}
	)
	let aceHwyInbondArrivalEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aceHwyInbondArrival
		}
	)
	let aceHwyInbondArrivalDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aceHwyInbondArrival
		}
	)

	//ABI Inbond 7512
	let abiInbond7512 = translate(
		Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
		AceHighwayAbiInbond7512Keys.TITLE
	)
	let abiInbond7512ListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: abiInbond7512
		}
	)
	let abiInbond7512AddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: abiInbond7512
		}
	)
	let abiInbond7512EditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: abiInbond7512
		}
	)
	let abiInbond7512DetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: abiInbond7512
		}
	)

	//ABI Inbond Event
	let abiInbondEvent = translate(
		Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
		AceHighwayAbiInbondEventKeys.TITLE
	)
	let abiInbondEventListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aceHwyInbondArrival
		}
	)
	let abiInbondEventAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aceHwyInbondArrival + " Stand Alone"
		}
	)
	let abiInbondEventEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aceHwyInbondArrival + " Stand Alone"
		}
	)
	let abiInbondEventDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aceHwyInbondArrival + " Stand Alone"
		}
	)

	//ACI HW Release Notice
	let aciHwReleaseNotice = translate(
		Namespace.ACI_HW_RELEASE_NOTICE,
		AciHwReleaseNoticeKeys.TITLE
	)
	let aciHwReleaseNoticeListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aciHwReleaseNotice
		}
	)
	let aciHwReleaseNoticeAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aciHwReleaseNotice
		}
	)
	let aciHwReleaseNoticeEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aciHwReleaseNotice
		}
	)
	let aciHwReleaseNoticeDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aciHwReleaseNotice
		}
	)

	// ACI Highway Inbond Arrival
	let arrivalCert = translate(
		Namespace.ARRIVAL_CERT,
		ArrivalCertKeys.TITLE
	)
	let arrivalCertListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: arrivalCert
		}
	)
	let arrivalCertAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: arrivalCert
		}
	)
	let arrivalCertEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: arrivalCert
		}
	)
	let arrivalCertDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: arrivalCert
		}
	)

	//EHBL Manifest
	let ehblManifest = translate(
		Namespace.EHBL_MANIFEST,
		EhblManifestKeys.TITLE
	)
	let ehblManifestListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: ehblManifest
		}
	)
	let ehblManifestAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: ehblManifest
		}
	)
	let ehblManifestEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: ehblManifest
		}
	)
	let ehblManifestDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: ehblManifest
		}
	)

	//EHBL House Bill
	let ehblHouseBill = translate(
		Namespace.EHBL_HOUSE_BILL,
		EhblHouseBillKeys.TITLE
	)
	let ehblHouseBillListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: ehblHouseBill
		}
	)
	let ehblHouseBillAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: ehblHouseBill
		}
	)
	let ehblHouseBillEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: ehblHouseBill
		}
	)
	let ehblHouseBillDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: ehblHouseBill
		}
	)

	//EHBL Close Message
	let ehblCloseMsg = translate(
		Namespace.EHBL_CLOSE_MSG,
		EhblCloseMsgKeys.TITLE
	)
	let ehblCloseMsgListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: ehblCloseMsg
		}
	)
	let ehblCloseMsgAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: ehblCloseMsg
		}
	)
	let ehblCloseMsgEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: ehblCloseMsg
		}
	)
	let ehblCloseMsgDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: ehblCloseMsg
		}
	)

	//Manifest Forward
	let ehblManifestForward = translate(
		Namespace.EHBL_HOUSE_BILL,
		EhblHouseBillKeys.MANIFEST_FORWARD_TITLE
	)
	let ehblManifestForwardListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: ehblManifestForward
		}
	)

	//DB Admin
	let dbAdminStatusConfig = translate(
		Namespace.DB_ADMIN_STATUS_CONFIG,
		DbAdminStatusConfigKeys.TITLE
	)
	let dbAdminStatusConfigListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: dbAdminStatusConfig
		}
	)
	let dbAdminStatusConfigAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: dbAdminStatusConfig
		}
	)
	let dbAdminStatusConfigEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: dbAdminStatusConfig
		}
	)
	let dbAdminStatusConfigDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: dbAdminStatusConfig
		}
	)
	//NBCI US CA PArty Master
	let nbciUsCaPartyMaster = translate(
		Namespace.NBCI_USCA_PARTY_MASTER,
		NBCIUSCAPartyMasterKeys.TITLE
	)
	let nbciPartyMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: nbciUsCaPartyMaster
		}
	)
	let nbciPartyMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: nbciUsCaPartyMaster
		}
	)
	let nbciPartyMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: nbciUsCaPartyMaster
		}
	)
	let nbciPartyMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: nbciUsCaPartyMaster
		}
	)

	//Manifest Fax Tracker
	let faxTracker = translate(
		Namespace.FAX_TRACKER,
		FaxTrackerKeys.TITLE
	)
	let faxTrackerListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: faxTracker
		}
	)
	let faxTrackerAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: faxTracker
		}
	)
	let faxTrackerEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: faxTracker
		}
	)
	let faxTrackerDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: faxTracker
		}
	)

	//NBCI Canada Invoice
	let nBCICanadaInvoice = translate(
		Namespace.N_B_C_I_CANADA_INVOICE,
		NBCICanadaInvoiceKeys.TITLE
	)
	let nBCICanadaInvoiceListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: nBCICanadaInvoice
		}
	)
	let nBCICanadaInvoiceAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: nBCICanadaInvoice
		}
	)

	let nBCICanadaInvoiceEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: nBCICanadaInvoice
		}
	)
	let nBCICanadaInvoiceDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: nBCICanadaInvoice
		}
	)

	let nbciUsInvoice = translate(
		Namespace.NBCI_US_INVOICE,
		NbciUsInvoiceKeys.TITLE
	)
	let nbciUsInvoiceListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: nbciUsInvoice
		}
	)
	let nbciUsInvoiceAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: nbciUsInvoice
		}
	)
	let nbciUsInvoiceEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: nbciUsInvoice
		}
	)
	let nbciUsInvoiceDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: nbciUsInvoice
		}
	)

	let nbciUploadTitle = translate(
		Namespace.US_PRODUCT_MASTER,
		UsProductMasterKeys.UPLOAD
	)

	//Billing Config
	let billingConfigTitle = translate(
		Namespace.BILLING_CONFIG,
		BillingConfigKeys.TITLE
	)
	let billingConfigListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: billingConfigTitle
		}
	)

	//Announcement 
	let announcement = translate(
		Namespace.ANNOUNCEMENT,
		AnnouncementKeys.TITLE
	)
	let announcementListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: announcement
		}
	)
	let announcementAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: announcement
		}
	)
	let announcementEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: announcement
		}
	)
	let announcementDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: announcement
		}
	)

	//ACE ISF 10 
	let aceIsf10 = translate(
		Namespace.ACE_ISF,
		AceIsfKeys.TITLE
	)
	let aceIsf10DetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aceIsf10
		}
	)
	let aceIsf10AddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aceIsf10
		}
	)
	let aceIsf10EditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aceIsf10
		}
	)
	let aceIsf10ListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aceIsf10
		}
	)

	//ACE ISF 5
	let aceIsf5 = translate(
		Namespace.ACE_ISF,
		AceIsfKeys.TITLE_ISF5
	)
	let aceIsf5DetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aceIsf5
		}
	)
	let aceIsf5AddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aceIsf5
		}
	)
	let aceIsf5EditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aceIsf5
		}
	)
	let aceIsf5ListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aceIsf5
		}
	)
	
	//ACE OCN
	let aceOcnBilloflading = translate(
		Namespace.ACE_OCN_BILLOFLADING,
		AceOcnBillofladingKeys.TITLE
	  )
	  let aceOcnBillofladingListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			  {
				  title: aceOcnBilloflading
			  }
	  )
	  let aceOcnBillofladingAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			  {
				  title: aceOcnBilloflading
			  }
	  )
	  let aceOcnBillofladingEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			  {
				  title: aceOcnBilloflading
			  }
	  )
	  let aceOcnBillofladingDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			  {
				  title: aceOcnBilloflading
			  }
	  )
	let aceOcnVesselDeparture = translate(
		Namespace.ACE_OCN_VESSEL_DEPARTURE,
		AceOcnVesselDepartureKeys.TITLE
	)
	let aceOcnVesselDepartureListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			{
				title: aceOcnVesselDeparture
			}
	)
	let aceOcnVesselDepartureAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			{
				title: aceOcnVesselDeparture
			}
	)
	let aceOcnVesselDepartureEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			{
				title: aceOcnVesselDeparture
			}
	)
	let aceOcnVesselDepartureDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			{
				title: aceOcnVesselDeparture
			}
	)

	  let aceOcnManifest = translate(
		Namespace.ACE_OCN_MANIFEST,
		AceOcnManifestKeys.TITLE
	  )
	  let aceOcnManifestListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			  {
				  title: aceOcnManifest
			  }
	  )
	  let aceOcnManifestAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			  {
				  title: aceOcnManifest
			  }
	  )
	  let aceOcnManifestEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			  {
				  title: aceOcnManifest
			  }
	  )
	  let aceOcnManifestDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			  {
				  title: aceOcnManifest
			  }
	  )

	  let vesselMaster = translate(
		Namespace.ACE_OCN_VESSEL_MASTER,
		AceOcnVesselMasterKeys.TITLE
	  )
	  let vesselMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			  {
				  title: vesselMaster
			  }
	  )
	  let vesselMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			  {
				  title: vesselMaster
			  }
	  )
	  let vesselMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			  {
				  title: vesselMaster
			  }
	  )
	  let vesselMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			  {
				  title: vesselMaster
			  }
		)
		
	let aceOcnVesselArrival = translate(
		Namespace.ACE_OCN_VESSEL_ARRIVAL,
		AceOcnVesselArrivalKeys.TITLE
	)
	let aceOcnVesselArrivalListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: aceOcnVesselArrival
		}
	)
	let aceOcnVesselArrivalAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: aceOcnVesselArrival
		}
	)
	let aceOcnVesselArrivalEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: aceOcnVesselArrival
		}
	)
	let aceOcnVesselArrivalDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: aceOcnVesselArrival
		}

	)

	//Party Config
	let partyConfig = translate(
		Namespace.PARTY_CONFIG,
		PartyConfigKeys.TITLE
	)
	let partyConfigListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			{
				title: partyConfig
			}
	)
	let partyConfigAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			{
				title: partyConfig
			}
	)
	let partyConfigEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			{
				title: partyConfig
			}
	)
	let partyConfigDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			{
				title: partyConfig
			}
	)

	let aesShipment = translate(
		Namespace.AES_SHIPMENT,
		AesShipmentKeys.TITLE
	  )
	  let aesShipmentListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			  {
				  title: aesShipment
			  }
	  )
	  let aesShipmentAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			  {
				  title: aesShipment
			  }
	  )
	  let aesShipmentEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			  {
				  title: aesShipment
			  }
	  )
	  let aesShipmentDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			  {
				  title: aesShipment
			  }
	  )

	  let aesPartyScreening = translate(
		Namespace.AES_PARTY_SCREENING,
		AesPartyScreeningKeys.TITLE
	  )
	  let aesPartyScreeningListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			  {
				  title: aesPartyScreening
			  }
	  )
	  let aesPartyScreeningAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			  {
				  title: aesPartyScreening
			  }
	  )
	  let aesPartyScreeningEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			  {
				  title: aesPartyScreening
			  }
	  )
	  let aesPartyScreeningDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			  {
				  title: aesPartyScreening
			  }
	  )  

	  let aesProductMaster = translate(
		Namespace.AES_SHIPMENT,
		AesShipmentKeys.PRODUCT_MASTER
	  )
	  let aesProductMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
			  {
				  title: aesProductMaster
			  }
	  )
	  let aesProductMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
			  {
				  title: aesProductMaster
			  }
	  )
	  let aesProductMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
			  {
				  title: aesProductMaster
			  }
	  )
	  let aesProductMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
			  {
				  title: aesProductMaster
			  }
	  )  

	//FDA PN 
	let fdapn = translate(
		Namespace.FDAPN,
		FdaPNKeys.TITLE
	)
	let fdapnDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: fdapn
		}
	)
	let fdapnAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: fdapn
		}
	)
	let fdapnEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: fdapn
		}
	)
	let fdapnListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: fdapn
		}
	)

	//FDA PN Product Master
	let fdapnProductMaster = translate(
		Namespace.FDAPN,
		FdaPNKeys.PRODUCT_MASTER
	)
	let fdapnProductMasterDetailsViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
		{
			title: fdapnProductMaster
		}
	)
	let fdapnProductMasterAddViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
		{
			title: fdapnProductMaster
		}
	)
	let fdapnProductMasterEditViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
		{
			title: fdapnProductMaster
		}
	)
	let fdapnProductMasterListViewTitle = translate(
		Namespace.UI_COMPONENT,
		UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
		{
			title: fdapnProductMaster
		}
	)

	return {
		productMasterListViewTitle,
		productMasterAddViewTitle,
		productMasterEditViewTitle,
		productMasterDetailsViewTitle,
		partyMasterListViewTitle,
		partyMasterAddViewTitle,
		partyMasterEditViewTitle,
		partyMasterDetailsViewTitle,
		partyMasterDetailsUploadTitle,
		arrivalmessageListViewTitle,
		arrivalmessageAddViewTitle,
		arrivalmessageEditViewTitle,
		arrivalmessageDetailsViewTitle,
		arrivalresponseListViewTitle,
		acioceanarrivalmessageListViewTitle,
		acioceanarrivalmessageAddViewTitle,
		acioceanarrivalmessageEditViewTitle,
		acioceanarrivalmessageDetailsViewTitle,
		aciairarrivalmessageListViewTitle,
		aciairarrivalmessageAddViewTitle,
		aciairarrivalmessageEditViewTitle,
		aciairarrivalmessageDetailsViewTitle,
		aciAirACRListViewTitle,
		aciAirACRAddViewTitle,
		aciAirACREditViewTitle,
		aciAirACRDetailsViewTitle,
		aciAirSSRListViewTitle,
		aciAirSSRAddViewTitle,
		aciAirSSREditViewTitle,
		aciAirSSRDetailsViewTitle,
		aciAirS10ListViewTitle,
		aciAirS10AddViewTitle,
		aciAirS10EditViewTitle,
		aciAirS10DetailsViewTitle,
		acioceanListViewTitle,
		acioceanAddViewTitle,
		acioceanEditViewTitle,
		acioceanDetailsViewTitle,
		conveyanceListViewTitle,
		conveyanceAddViewTitle,
		conveyanceEditViewTitle,
		conveyanceDetailsViewTitle,
		a6aListViewTitle,
		a6aAddViewTitle,
		a6aEditViewTitle,
		a6aDetailsViewTitle,
		docGenPurchaseOrderListViewTitle,
		docGenPurchaseOrderAddViewTitle,
		docGenPurchaseOrderEditViewTitle,
		docGenPurchaseOrderDetailsViewTitle,
		statusMessageListViewTitle,
		statusMessageAddViewTitle,
		statusMessageEditViewTitle,
		statusMessageDetailsViewTitle,
		h2hSubFailsListViewTitle,
		h2hSubFailsAddViewTitle,
		h2hSubFailsEditViewTitle,
		h2hSubFailsDetailsViewTitle,
		flightMatrixListViewTitle,
		flightMatrixAddViewTitle,
		flightMatrixEditViewTitle,
		flightMatrixDetailsViewTitle,
		validationErrorLogListViewTitle,
		invoiceListViewTitle,
		invoiceAddViewTitle,
		invoiceEditViewTitle,
		invoiceDetailsViewTitle,
		hSCodeListViewTitle,
		hSCodeAddViewTitle,
		hSCodeEditViewTitle,
		hSCodeDetailsViewTitle,
		advanceCommercialInformationAirListViewTitle,
		advanceCommercialInformationAirAddViewTitle,
		sbciInvoiceListViewTitle,
		sbciInvoiceAddViewTitle,
		sbciInvoiceEditViewTitle,
		sbciInvoiceDetailsViewTitle,
		manifestListViewTitle,
		manifestAddViewTitle,
		manifestEditViewTitle,
		manifestDetailsViewTitle,
		sbciProductMasterListViewTitle,
		sbciProductMasterAddViewTitle,
		sbciProductMasterEditViewTitle,
		sbciProductMasterDetailsViewTitle,
		sbciManifestListViewTitle,
		sbciManifestAddViewTitle,
		sbciManifestEditViewTitle,
		sbciManifestDetailsViewTitle,
		sbciFileUploadTitle,
		equipmentMasterListViewTitle,
		equipmentMasterAddViewTitle,
		equipmentMasterEditViewTitle,
		equipmentMasterDetailsViewTitle,
		conveyanceMastListViewTitle,
		conveyanceMastAddViewTitle,
		conveyanceMastEditViewTitle,
		conveyanceMastDetailsViewTitle,
		aciHighwayStatusQueryListViewTitle,
		aciHighwayStatusQueryAddViewTitle,
		aciHighwayStatusQueryEditViewTitle,
		aciHighwayStatusQueryDetailsViewTitle,
		crewMasterListViewTitle,
		crewMasterAddViewTitle,
		crewMasterEditViewTitle,
		crewMasterDetailsViewTitle,
		usProductMasterListViewTitle,
		usProductMasterAddViewTitle,
		usProductMasterEditViewTitle,
		usProductMasterDetailsViewTitle,
		aciHighwayCargoListViewTitle,
		aciHighwayCargoAddViewTitle,
		aciHighwayCargoEditViewTitle,
		aciHighwayCargoDetailsViewTitle,
		aciHighwayD4Notice,
		aciHighwayD4NoticeListViewTitle,
		aciHighwayReleaseNotice,
		aciHighwayReleaseNoticeListViewTitle,
		aceHighwayListViewTitle,
		aceHighwayAddViewTitle,
		aceHighwayEditViewTitle,
		aceHighwayDetailsViewTitle,
		aceHighwayManifestListViewTitle,
		aceHighwayManifestAddViewTitle,
		aceHighwayManifestEditViewTitle,
		aceHighwayManifestDetailsViewTitle,
		aceHwyInbondArrivalListViewTitle,
		aceHwyInbondArrivalAddViewTitle,
		aceHwyInbondArrivalEditViewTitle,
		aceHwyInbondArrivalDetailsViewTitle,
		abiInbond7512ListViewTitle,
		abiInbond7512AddViewTitle,
		abiInbond7512EditViewTitle,
		abiInbond7512DetailsViewTitle,
		abiInbondEventListViewTitle,
		abiInbondEventAddViewTitle,
		abiInbondEventEditViewTitle,
		abiInbondEventDetailsViewTitle,
		ehblManifestListViewTitle,
		ehblManifestAddViewTitle,
		ehblManifestEditViewTitle,
		ehblManifestDetailsViewTitle,
		ehblHouseBillListViewTitle,
		ehblHouseBillAddViewTitle,
		ehblHouseBillEditViewTitle,
		ehblHouseBillDetailsViewTitle,
		ehblCloseMsgListViewTitle,
		ehblCloseMsgAddViewTitle,
		ehblCloseMsgEditViewTitle,
		ehblCloseMsgDetailsViewTitle,
		ehblManifestForward,
		ehblManifestForwardListViewTitle,
		arrivalCertListViewTitle,
		arrivalCertAddViewTitle,
		arrivalCertEditViewTitle,
		arrivalCertDetailsViewTitle,
		aciHwReleaseNoticeListViewTitle,
		aciHwReleaseNoticeAddViewTitle,
		aciHwReleaseNoticeEditViewTitle,
		aciHwReleaseNoticeDetailsViewTitle,
		dbAdminStatusConfigListViewTitle,
		dbAdminStatusConfigAddViewTitle,
		dbAdminStatusConfigEditViewTitle,
		dbAdminStatusConfigDetailsViewTitle,
		usDeclarationsListViewTitle,
		usDeclarationsAddViewTitle,
		usDeclarationsEditViewTitle,
		usDeclarationsDetailsViewTitle,
		nbciPartyMasterListViewTitle,
		nbciPartyMasterAddViewTitle,
		nbciPartyMasterEditViewTitle,
		nbciPartyMasterDetailsViewTitle,
		faxTrackerListViewTitle,
		faxTrackerAddViewTitle,
		faxTrackerEditViewTitle,
		faxTrackerDetailsViewTitle,
		nBCICanadaInvoiceListViewTitle,
		nBCICanadaInvoiceAddViewTitle,
		nBCICanadaInvoiceEditViewTitle,
		nBCICanadaInvoiceDetailsViewTitle,
		nbciUsInvoiceListViewTitle,
		nbciUsInvoiceAddViewTitle,
		nbciUsInvoiceEditViewTitle,
		nbciUsInvoiceDetailsViewTitle,
		nbciUploadTitle,
		billingConfigListViewTitle,
		announcementListViewTitle,
		announcementAddViewTitle,
		announcementEditViewTitle,
		announcementDetailsViewTitle,
		aceIsf10DetailsViewTitle,
		aceIsf10AddViewTitle,
		aceIsf10EditViewTitle,
		aceIsf10ListViewTitle,
		aceIsf5DetailsViewTitle,
		aceIsf5AddViewTitle,
		aceIsf5EditViewTitle,
		aceIsf5ListViewTitle,
		aceOcnBillofladingListViewTitle,
		aceOcnBillofladingAddViewTitle,
		aceOcnBillofladingEditViewTitle,
		aceOcnBillofladingDetailsViewTitle,
		aceOcnManifestListViewTitle,
		aceOcnManifestAddViewTitle,
		aceOcnManifestEditViewTitle,
		aceOcnManifestDetailsViewTitle,
		vesselMasterListViewTitle,
		vesselMasterAddViewTitle,
		vesselMasterEditViewTitle,
		vesselMasterDetailsViewTitle,
		aceOcnVesselArrivalListViewTitle,
		aceOcnVesselArrivalAddViewTitle,
		aceOcnVesselArrivalEditViewTitle,
		aceOcnVesselArrivalDetailsViewTitle,
		aceOcnVesselDepartureListViewTitle,
		aceOcnVesselDepartureAddViewTitle,
		aceOcnVesselDepartureEditViewTitle,
		aceOcnVesselDepartureDetailsViewTitle,
		partyConfigListViewTitle,
		partyConfigAddViewTitle,
		partyConfigEditViewTitle,
		partyConfigDetailsViewTitle,
		aesShipmentListViewTitle,
		aesShipmentAddViewTitle,
		aesShipmentEditViewTitle,
		aesShipmentDetailsViewTitle,
		aesPartyScreeningListViewTitle,
		aesPartyScreeningAddViewTitle,
		aesPartyScreeningEditViewTitle,
		aesPartyScreeningDetailsViewTitle,
		aesProductMasterListViewTitle,
		aesProductMasterAddViewTitle,
		aesProductMasterEditViewTitle,
		aesProductMasterDetailsViewTitle,
		fdapnDetailsViewTitle,
		fdapnAddViewTitle,
		fdapnEditViewTitle,
		fdapnListViewTitle,
		fdapnProductMasterDetailsViewTitle,
		fdapnProductMasterAddViewTitle,
		fdapnProductMasterEditViewTitle,
		fdapnProductMasterListViewTitle,
	}
}

export default useBreadcrumbNameMap

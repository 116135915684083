import React, { useContext } from 'react'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'
import makeValidationSchema from './TaciOcnA6aSNMakeValidationSchema'
import _ from 'lodash'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  email: "",
  phno: "",
  name: "",
  snType: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { getTranslatedText } = useContext(A6ATranslationContext)

	return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
        <CngTextField
          name="name"
          label={getTranslatedText('A6A', 'name')}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
        <CngTextField
          name="email"
          label={getTranslatedText('A6A', 'email')}
          disabled={disabled}
          inputProps={{ maxLength: 300 }}
          required
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.phno}>
        <CngTextField
          name="phno"
          label={getTranslatedText('A6A', 'phno')}
          disabled={disabled}
          inputProps={{ maxLength: 12 }}
          size="small"
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snType}>
        <CngCodeMasterAutocompleteField
          name="snType"
          label={getTranslatedText('A6A', 'snType')}
          disabled={disabled}
          codeType='ACIOCN_STATUS_NOTIFI_TYPE'
          required
          size="small"
        />
      </CngGridItem>
    </Grid>
	)
}

const TaciOcnA6aSNFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TaciOcnA6aSNFormProperties

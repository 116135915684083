import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import { NaCustomsOfficeAutocompleteField } from 'src/components/na'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
    form: {
        field: {
            CngTextField,
            CngCountryAutocompleteField,
            CngSwitchField
        }
    },
    CngGridItem
} = components

function A6APrintingSection(props) {
    const { disabled, shouldHideMap, editPage } = props
    const { setValue } = useFormContext();
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const docFormat = useWatch({ name: "docFormat" })
    const templateFlag = useWatch({ name: "templateFlag" })
    const a6aStatus = useWatch({ name: "a6aStatus" })

    let isEditable = false;
    const templateStatus = a6aStatus === 1002 ? true : false

    if (editPage && templateFlag && templateStatus) {
        isEditable = true;
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6A', 'a8aPrinting')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>
                {(docFormat === "687") && (
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
                            <CngTextField
                                name="voyageNo"
                                label={getTranslatedText('A6A', 'voyageNo')}
                                disabled={disabled}
                                inputProps={{ maxLength: 10 }}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfPortArrival}>
                            <NaCustomsOfficeAutocompleteField
                                name="customsOffOfPortArrival"
                                label={getTranslatedText('A6A', 'customsOffOfPortArrival')}
                                disabled={disabled}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.foreignPortOfLading}>
                            <CngCountryAutocompleteField
                                name="foreignPortOfLading"
                                label={getTranslatedText('A6A', 'foreignPortOfLading')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingCity}>
                            <CngTextField
                                name="cargoLoadingCity"
                                label={getTranslatedText('A6A', 'cargoLoadingCity')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingPort}>
                            <CngTextField
                                name="cargoLoadingPort"
                                label={getTranslatedText('A6A', 'cargoLoadingPort')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfManifestDestination}>
                            <NaCustomsOfficeAutocompleteField
                                name="customsOffOfManifestDestination"
                                label={getTranslatedText('A6A', 'customsOffOfManifestDestination')}
                                disabled={disabled}
                                size='small'
                            />
                        </CngGridItem>
                    </Grid>
                )}

                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfDischarge}>
                        <NaCustomsOfficeAutocompleteField
                            name="customsOffOfDischarge"
                            label={getTranslatedText('A6A', 'customsOffOfDischarge')}
                            disabled={disabled}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.terminalName}>
                        <CngTextField
                            name="terminalName"
                            label={getTranslatedText('A6A', 'terminalName')}
                            disabled={disabled}
                            inputProps={{ maxLength: 4 }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierName}>
                        <CngTextField
                            name="carrierName"
                            label={getTranslatedText('A6A', 'carrierName')}
                            disabled={disabled}
                            inputProps={{ maxLength: 25 }}
                            size='small'
                        />
                    </CngGridItem>
                </Grid>

                <Grid container spacing={1} style={{ paddingTop: "10px" }}>
                    <CngGridItem xs={12} sm={4} md={2} shouldHide={shouldHideMap.templateFlag}>
                        <CngSwitchField
                            name="templateFlag"
                            label={getTranslatedText('A6A', 'templateFlag')}
                            disabled={disabled || isEditable}
                            labelPlacement="bottom"
                        />
                    </CngGridItem>

                    {(templateFlag === true) && (
                        <CngGridItem xs={12} sm={8} md={6} shouldHide={shouldHideMap.templateName}>
                            <CngTextField
                                name="templateName"
                                inputProps={{ maxLength: 50 }}
                                label={getTranslatedText('A6A', 'templateName') + " *"}
                                disabled={disabled || isEditable}
                                size='small'
                                onBlur={(e) => { setValue("templateName", e.target.value.toUpperCase().trim()) }}
                            />
                        </CngGridItem>
                    )}
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default A6APrintingSection
import React, { useContext, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useWatch, useFormContext } from "react-hook-form";
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import { NaCustomsOfficeAutocompleteField, NaSublocationAutocompleteField } from 'src/components/na'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
    form: {
        field: {
            CngTextField,
            CngDateTimeField,
            CngCountryAutocompleteField,
            CngPortAutocompleteField
        }
    },
    CngGridItem
} = components

function A6AScheduleSection(props) {
    const { disabled, shouldHideMap } = props
    const { getTranslatedText } = useContext(A6ATranslationContext)
    const { setValue } = useFormContext();

    const docFormat = useWatch({ name: "docFormat" })
    const customsProcedure = useWatch({ name: "customsProcedure" })

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6A', 'schedule')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>
                {(docFormat === "83") && (
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.a6ConvRefNo}>
                            <CngTextField
                                name="a6ConvRefNo"
                                label={getTranslatedText('A6A', 'a6ConvRefNo')}
                                inputProps={{ maxLength: 25 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('a6ConvRefNo', e.target.value.toUpperCase()) }}    

                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={(shouldHideMap.vesselName)}>
                            <CngTextField
                                name="vesselName"
                                label={getTranslatedText('A6A', 'vesselName')}
                                inputProps={{ maxLength: 28 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('vesselName', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
                            <CngTextField
                                name="voyageNo"
                                label={getTranslatedText('A6A', 'voyageNo')}
                                inputProps={{ maxLength: 10 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('voyageNo', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoading}>
                            <CngPortAutocompleteField
                                name="portOfLoading"
                                label={getTranslatedText('A6A', 'portOfLoading')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.estimatedLoadingDate}>
                            <CngDateTimeField
                                name="estimatedLoadingDate"
                                format="YYYY-MM-DD HH:mm:ss"
                                label={getTranslatedText('A6A', 'estimatedLoadingDate')}
                                disabled={disabled}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfArrival}>
                            <CngPortAutocompleteField
                                name="portOfArrival"
                                label={getTranslatedText('A6A', 'portOfArrival')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivalTerminal}>
                            <CngTextField
                                name="arrivalTerminal"
                                label={getTranslatedText('A6A', 'arrivalTerminal')}
                                disabled={disabled}
                                inputProps={
                                    { maxLength: 30 }
                                }
                                required
                                size='small'
                                onBlur={(e) => { setValue('arrivalTerminal', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.pierOfArrival}>
                            <CngTextField
                                name="pierOfArrival"
                                label={getTranslatedText('A6A', 'pierOfArrival')}
                                disabled={disabled}
                                inputProps={{ maxLength: 4 }}
                                size='small'
                                onBlur={(e) => { setValue('pierOfArrival', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        {customsProcedure!=="26" && (<>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfPortArrival}>
                            <NaCustomsOfficeAutocompleteField
                                name="customsOffOfPortArrival"
                                label={getTranslatedText('A6A', 'customsOffOfPortArrival')}
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfPortArrivalSubLocCode}>
                            <NaSublocationAutocompleteField
                                name="customsOffOfPortArrivalSubLocCode"
                                label={getTranslatedText('A6A', 'customsOffOfPortArrivalSubLocCode')}
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        </>)}
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.foreignPortOfLading}>
                            <CngCountryAutocompleteField
                                name="foreignPortOfLading"
                                label={getTranslatedText('A6A', 'foreignPortOfLading')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingCity}>
                            <CngTextField
                                name="cargoLoadingCity"
                                label={getTranslatedText('A6A', 'cargoLoadingCity')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoLoadingCity', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingPort}>
                            <CngTextField
                                name="cargoLoadingPort"
                                label={getTranslatedText('A6A', 'cargoLoadingPort')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoLoadingPort', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCountry}>
                            <CngCountryAutocompleteField
                                name="cargoDestinationCountry"
                                label={getTranslatedText('A6A', 'cargoDestinationCountry')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCity}>
                            <CngTextField
                                name="cargoDestinationCity"
                                label={getTranslatedText('A6A', 'cargoDestinationCity')}
                                disabled={disabled}
                                inputProps={{ maxLength: 35 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationCity', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationPort}>
                            <CngTextField
                                name="cargoDestinationPort"
                                label={getTranslatedText('A6A', 'cargoDestinationPort')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationPort', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        {customsProcedure!=="26" && (<>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfManifestDestination}>
                            <NaCustomsOfficeAutocompleteField
                                name="customsOffOfManifestDestination"
                                label={getTranslatedText('A6A', 'customsOffOfManifestDestination')}
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfManifestDestinationSubLocCode}>
                            <NaSublocationAutocompleteField
                                name="customsOffOfManifestDestinationSubLocCode"
                                label={getTranslatedText('A6A', 'customsOffOfManifestDestinationSubLocCode')}
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        </>)}
                    </Grid>
                )}

                {(docFormat === "711") && (
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.a6ConvRefNo}>
                            <CngTextField
                                name="a6ConvRefNo"
                                label={getTranslatedText('A6A', 'a6ConvRefNo')}
                                inputProps={{ maxLength: 25 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('a6ConvRefNo', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={(shouldHideMap.vesselName)}>
                            <CngTextField
                                name="vesselName"
                                label={getTranslatedText('A6A', 'vesselName')}
                                inputProps={{ maxLength: 28 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('vesselName', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
                            <CngTextField
                                name="voyageNo"
                                label={getTranslatedText('A6A', 'voyageNo')}
                                inputProps={{ maxLength: 10 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('voyageNo', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoading}>
                            <CngPortAutocompleteField
                                name="portOfLoading"
                                label={getTranslatedText('A6A', 'portOfLoading')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.terminalOfLoading}>
                            <CngTextField
                                name="terminalOfLoading"
                                label={getTranslatedText('A6A', 'terminalOfLoading')}
                                disabled={disabled}
                                inputProps={{ maxLength: 30 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('terminalOfLoading', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.pierOfLoading}>
                            <CngTextField
                                name="pierOfLoading"
                                label={getTranslatedText('A6A', 'pierOfLoading')}
                                disabled={disabled}
                                inputProps={{ maxLength: 4 }}
                                size='small'
                                onBlur={(e) => { setValue('pierOfLoading', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselDepartureDate}>
                            <CngDateTimeField
                                name="vesselDepartureDate"
                                label={getTranslatedText('A6A', 'vesselDepartureDate')}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOfficeOfExit}>
                            <NaCustomsOfficeAutocompleteField
                                name="customsOfficeOfExit"
                                label={getTranslatedText('A6A', 'customsOfficeOfExit')}
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.foreignPortOfLading}>
                            <CngCountryAutocompleteField
                                name="foreignPortOfLading"
                                label={getTranslatedText('A6A', 'foreignPortOfLading')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingCity}>
                            <CngTextField
                                name="cargoLoadingCity"
                                label={getTranslatedText('A6A', 'cargoLoadingCity')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoLoadingCity', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingPort}>
                            <CngTextField
                                name="cargoLoadingPort"
                                label={getTranslatedText('A6A', 'cargoLoadingPort')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoLoadingPort', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCountry}>
                            <CngCountryAutocompleteField
                                name="cargoDestinationCountry"
                                label={getTranslatedText('A6A', 'cargoDestinationCountry')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCity}>
                            <CngTextField
                                name="cargoDestinationCity"
                                label={getTranslatedText('A6A', 'cargoDestinationCity')}
                                disabled={disabled}
                                inputProps={{ maxLength: 35 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationCity', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationPort}>
                            <CngTextField
                                name="cargoDestinationPort"
                                label={getTranslatedText('A6A', 'cargoDestinationPort')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationPort', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfDischarge}>
                            <CngPortAutocompleteField
                                name="portOfDischarge"
                                label={getTranslatedText('A6A', 'portOfDischarge')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                    </Grid>
                )}

                {(docFormat === "695") && (
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.a6ConvRefNo}>
                            <CngTextField
                                name="a6ConvRefNo"
                                label={getTranslatedText('A6A', 'a6ConvRefNo')}
                                inputProps={{ maxLength: 25 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('a6ConvRefNo', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={(shouldHideMap.vesselName)}>
                            <CngTextField
                                name="vesselName"
                                label={getTranslatedText('A6A', 'vesselName')}
                                inputProps={{ maxLength: 28 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('vesselName', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
                            <CngTextField
                                name="voyageNo"
                                label={getTranslatedText('A6A', 'voyageNo')}
                                inputProps={{ maxLength: 10 }}
                                disabled={disabled}
                                required
                                size='small'
                                onBlur={(e) => { setValue('voyageNo', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoading}>
                            <CngPortAutocompleteField
                                name="portOfLoading"
                                label={getTranslatedText('A6A', 'portOfLoading')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfArrival}>
                            <CngPortAutocompleteField
                                name="portOfArrival"
                                label={getTranslatedText('A6A', 'portOfArrival')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivalTerminal}>
                            <CngTextField
                                name="arrivalTerminal"
                                label={getTranslatedText('A6A', 'arrivalTerminal')}
                                disabled={disabled}
                                inputProps={{ maxLength: 30 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('arrivalTerminal', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.pierOfArrival}>
                            <CngTextField
                                name="pierOfArrival"
                                label={getTranslatedText('A6A', 'pierOfArrival')}
                                disabled={disabled}
                                inputProps={{ maxLength: 4 }}
                                size='small'
                                onBlur={(e) => { setValue('pierOfArrival', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfPortArrival}>
                            <NaCustomsOfficeAutocompleteField
                                name="customsOffOfPortArrival"
                                label={getTranslatedText('A6A', 'customsOffOfPortArrival')}
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.foreignPortOfLading}>
                            <CngCountryAutocompleteField
                                name="foreignPortOfLading"
                                label={getTranslatedText('A6A', 'foreignPortOfLading')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingCity}>
                            <CngTextField
                                name="cargoLoadingCity"
                                label={getTranslatedText('A6A', 'cargoLoadingCity')}
                                disabled={disabled}
                                inputProps={
                                    { maxLength: 25 }
                                }
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoLoadingCity', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoLoadingPort}>
                            <CngTextField
                                name="cargoLoadingPort"
                                label={getTranslatedText('A6A', 'cargoLoadingPort')}
                                disabled={disabled}
                                inputProps={
                                    { maxLength: 25 }
                                }
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCountry}>
                            <CngCountryAutocompleteField
                                name="cargoDestinationCountry"
                                label={getTranslatedText('A6A', 'cargoDestinationCountry')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCity}>
                            <CngTextField
                                name="cargoDestinationCity"
                                label={getTranslatedText('A6A', 'cargoDestinationCity')}
                                disabled={disabled}
                                inputProps={{ maxLength: 35 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationCity', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationPort}>
                            <CngTextField
                                name="cargoDestinationPort"
                                label={getTranslatedText('A6A', 'cargoDestinationPort')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationPort', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsOffOfManifestDestination}>
                            <NaCustomsOfficeAutocompleteField
                                name="customsOffOfManifestDestination"
                                label={getTranslatedText('A6A', 'customsOffOfManifestDestination')}
                                disabled={disabled}
                                required
                                size='small'
                            />
                        </CngGridItem>
                    </Grid>
                )}

                {(docFormat === "687") && (
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCountry}>
                            <CngCountryAutocompleteField
                                name="cargoDestinationCountry"
                                label={getTranslatedText('A6A', 'cargoDestinationCountry')}
                                disabled={disabled}
                                lookupProps={{
                                    label: (record) => {
                                        return `${record.code};${record.descriptionEn}`
                                    }
                                }}
                                required
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationCity}>
                            <CngTextField
                                name="cargoDestinationCity"
                                label={getTranslatedText('A6A', 'cargoDestinationCity')}
                                disabled={disabled}
                                inputProps={{ maxLength: 35 }}
                                required
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationCity', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDestinationPort}>
                            <CngTextField
                                name="cargoDestinationPort"
                                label={getTranslatedText('A6A', 'cargoDestinationPort')}
                                disabled={disabled}
                                inputProps={{ maxLength: 25 }}
                                size='small'
                                onBlur={(e) => { setValue('cargoDestinationPort', e.target.value.toUpperCase()) }}    
                            />
                        </CngGridItem>

                    </Grid>
                )}
            </CngSection>
        </Grid>
    )
}

export default A6AScheduleSection
import React, { useEffect, useState, useContext } from "react";
import { constants, useServices } from 'cng-web-lib'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'
import A6aApiUrls from "src/apiUrls/A6aApiUrls";
import clsx from 'clsx'
import moment from 'moment'

const { filter: { EQUAL } } = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function D4Response({ showNotification, a6aId, ccnValue }) {
  const { fetchRecords } = useServices()
  const { getTranslatedText } = useContext(A6ATranslationContext)

  const classes = useStyles()
  
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  function fetchA6AD4Response() {
    return fetchRecords.execute(A6aApiUrls.GET_D4,
      {
        filters: [
          { field: 'noticeType', operator: EQUAL, value: 'OCEAN' },
          { field: 'cbsaRefNo', operator: EQUAL, value: ccnValue }
        ]
      }, (res) => res.content)
  }

  function showA6AD4Response() {
    if (ccnValue) {
      Promise.all([fetchA6AD4Response()])
        .then(([respData]) => setData(
          respData.sort(function (a, b) {
            return new Date(b.cbsaProcessDateTime) - new Date(a.cbsaProcessDateTime);
          })
        ))
    }
  }

  useEffect(() => { showA6AD4Response() }, [])

  const columns = [
    {
      field: "cbsaRefNo",
      title: getTranslatedText('A6A', 'cbsaRefNo'),
    },
    {
      field: "cbsaProcessDateTime",
      title: getTranslatedText('A6A', 'cbsaProcessingDate'),
      render: (rowData) =>
        rowData.cbsaProcessDateTime &&
        moment(rowData.cbsaProcessDateTime).tz("Canada/Eastern").format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "receiveDateTime",
      title: getTranslatedText('A6A', 'systemDateTime'),
      render: (rowData) =>
        rowData.receiveDateTime &&
        moment(rowData.receiveDateTime).tz("Canada/Eastern").format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "documentType",
      title: getTranslatedText('A6A', 'documentType'),
    },
    {
      field: "remarks",
      title: getTranslatedText('A6A', 'remarks'),
    }
  ]

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}
export default D4Response;

import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import A6aKeys from 'src/constants/locale/key/A6a'

const A6ATranslationContext = React.createContext()

const A6ATranslationConsumer = A6ATranslationContext.Consumer

const A6ATranslationProvider = ({ children }) => {
    const { translate } = useTranslation([Namespace.A6A])

    const translation = {
        A6A: {
            a6a: translate(Namespace.A6A, A6aKeys.TITLE),
            a6aresponse: translate(Namespace.A6A, A6aKeys.RESPONSE),
            docFormat: translate(Namespace.A6A, A6aKeys.DOC_FORMAT),
            carrierCode: translate(Namespace.A6A, A6aKeys.CARRIER_CODE),
            ccn: translate(Namespace.A6A, A6aKeys.CCN),
            customsProcedure: translate(Namespace.A6A, A6aKeys.CUSTOMS_PROCEDURE),
            bolNo: translate(Namespace.A6A, A6aKeys.BOL_NO),
            vesselName: translate(Namespace.A6A, A6aKeys.VESSEL_NAME),
            voyageNo: translate(Namespace.A6A, A6aKeys.VOYAGE_NO),
            portOfLoading: translate(Namespace.A6A, A6aKeys.PORT_OF_LOADING),
            portOfArrival: translate(Namespace.A6A, A6aKeys.PORT_OF_ARRIVAL),
            customsOffOfManifestDestination: translate(Namespace.A6A, A6aKeys.CUSTOMS_OFF_OF_MANIFEST_DESTINATION),
            creationdatetime: translate(Namespace.A6A, A6aKeys.CREATIONDATETIME),
            status: translate(Namespace.A6A, A6aKeys.RECORD_STATUS),
            editMultipleRecords: translate(Namespace.A6A, A6aKeys.EDIT_MULTIPLE_RECORDS),
            editSentRecords: translate(Namespace.A6A, A6aKeys.EDIT_SENT_RECORDS),
            editButton: translate(Namespace.A6A, A6aKeys.EDIT_BUTTON),
            viewButton: translate(Namespace.A6A, A6aKeys.VIEW_BUTTON),
            cloneButton: translate(Namespace.A6A, A6aKeys.CLONE_BUTTON),
            viewMultipleRecords: translate(Namespace.A6A, A6aKeys.VIEW_MULTIPLE_RECORDS),
            cloneMultipleRecords: translate(Namespace.A6A, A6aKeys.CLONE_MULTIPLE_RECORDS),
            a6aTable: translate(Namespace.A6A, A6aKeys.A6A_TABLE),
            cancelButton: translate(Namespace.A6A, A6aKeys.CANCEL_BUTTON),
            submitButton: translate(Namespace.A6A, A6aKeys.SUBMIT_BUTTON),
            deleteButton: translate(Namespace.A6A, A6aKeys.DELETE_BUTTON),
            submitSentRecord: translate(Namespace.A6A, A6aKeys.SUBMIT_SENT_RECORD),
            submitSuccessMsg: translate(Namespace.A6A, A6aKeys.SUBMIT_SUCCESS_MSG),
            submitErrorMsg: translate(Namespace.A6A, A6aKeys.SUBMIT_ERROR_MSG),
            submitRecordDialogContent: translate(Namespace.A6A, A6aKeys.SUBMIT_RECORD_DIALOG_CONTENT),
            submitRecord: translate(Namespace.A6A, A6aKeys.SUBMIT_RECORD),
            deleteMultipleRecords: translate(Namespace.A6A, A6aKeys.DELETE_MULTIPLE_RECORDS),
            printaNormal: translate(Namespace.A6A, A6aKeys.NORMAL_PRINT_A6A),
            printMultiplrRecords: translate(Namespace.A6A, A6aKeys.PRINT_MULTIPLE_RECORDS),
            printA10: translate(Namespace.A6A, A6aKeys.PRINT_A10),
            printA8A: translate(Namespace.A6A, A6aKeys.PRINT_A8A),
            messageFunction: translate(Namespace.A6A, A6aKeys.MESSAGE_FUNCTION),
            supplementaryMatch: translate(Namespace.A6A, A6aKeys.SUPPLEMENTARY_MATCH),
            riskAssessmentStatus: translate(Namespace.A6A, A6aKeys.RISK_ASSESSMENT_STATUS),
            createdByLoginId: translate(Namespace.A6A, A6aKeys.CREATED_BY_LOGIN_ID),

            header: translate(Namespace.A6A, A6aKeys.HEADER),
            schedule: translate(Namespace.A6A, A6aKeys.SCHEDULE),
            chargingOption: translate(Namespace.A6A, A6aKeys.CHARGING_OPTION),
            recordStatus: translate(Namespace.A6A, A6aKeys.RECORD_STATUS),
            uniqueConsignmentRefNo: translate(Namespace.A6A, A6aKeys.UNIQUE_CONSIGNMENT_REF_NO),
            originalCcn: translate(Namespace.A6A, A6aKeys.ORIGINAL_CCN),
            mot: translate(Namespace.A6A, A6aKeys.MOT),
            specialIns: translate(Namespace.A6A, A6aKeys.SPECIAL_INS),
            supplementaryReqInd: translate(Namespace.A6A, A6aKeys.SUPPLEMENTARY_REQ_IND),
            bulkShipment: translate(Namespace.A6A, A6aKeys.BULK_SHIPMENT),
            a6ConvRefNo: translate(Namespace.A6A, A6aKeys.A6_CONV_REF_NO),
            terminalOfLoading: translate(Namespace.A6A, A6aKeys.TERMINAL_OF_LOADING),
            pierOfLoading: translate(Namespace.A6A, A6aKeys.PIER_OF_LOADING),
            estimatedLoadingDate: translate(Namespace.A6A, A6aKeys.ESTIMATED_LOADING_DATE),
            vesselDepartureDate: translate(Namespace.A6A, A6aKeys.VESSEL_DEPARTURE_DATE),
            customsOfficeOfExit: translate(Namespace.A6A, A6aKeys.CUSTOMS_OFFICE_OF_EXIT),
            portOfArrival: translate(Namespace.A6A, A6aKeys.PORT_OF_ARRIVAL),
            pierOfArrival: translate(Namespace.A6A, A6aKeys.PIER_OF_ARRIVAL),
            foreignPortOfLading: translate(Namespace.A6A, A6aKeys.FOREIGN_PORT_OF_LADING),
            cargoLoadingCity: translate(Namespace.A6A, A6aKeys.CARGO_LOADING_CITY),
            cargoLoadingPort: translate(Namespace.A6A, A6aKeys.CARGO_LOADING_PORT),
            customsOffOfPortArrival: translate(Namespace.A6A, A6aKeys.CUSTOMS_OFF_OF_PORT_ARRIVAL),
            customsOffOfPortArrivalSubLocCode: translate(Namespace.A6A, A6aKeys.CUSTOMS_OFF_OF_PORT_ARRIVAL_SUB_LOC_CODE),
            customsOffOfManifestDestinationSubLocCode: translate(Namespace.A6A, A6aKeys.CUSTOMS_OFF_OF_MANIFEST_DESTINATION_SUB_LOC_CODE),
            cargoDestinationCountry: translate(Namespace.A6A, A6aKeys.CARGO_DESTINATION_COUNTRY),
            cargoDestinationCity: translate(Namespace.A6A, A6aKeys.CARGO_DESTINATION_CITY),
            cargoDestinationPort: translate(Namespace.A6A, A6aKeys.CARGO_DESTINATION_PORT),
            customsOffOfDischarge: translate(Namespace.A6A, A6aKeys.CUSTOMS_OFF_OF_DISCHARGE),
            arrivalTerminal: translate(Namespace.A6A, A6aKeys.ARRIVAL_TERMINAL),
            carrierName: translate(Namespace.A6A, A6aKeys.CARRIER_NAME),
            terminalName: translate(Namespace.A6A, A6aKeys.TERMINAL_NAME),
            portOfDischarge: translate(Namespace.A6A, A6aKeys.PORT_OF_DISCHARGE),
            templateFlag: translate(Namespace.A6A, A6aKeys.TEMPLATE_FLAG),
            templateName: translate(Namespace.A6A, A6aKeys.TEMPLATE_NAME),
            a6aExports: translate(Namespace.A6A, A6aKeys.A6aExports.TITLE),
            exportTransNo: translate(Namespace.A6A, A6aKeys.A6aExports.EXPORT_TRANS_NO),
            a6aPrevious: translate(Namespace.A6A, A6aKeys.A6aPrevious.TITLE),
            previousCcn: translate(Namespace.A6A, A6aKeys.A6aPrevious.PREVIOUS_CCN),
            taciOcnA6aParties: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.TITLE),
            partyType: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.PARTY_TYPE),
            partyName1: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.PARTY_NAME1),
            partyName2: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.PARTY_NAME2),
            address1: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.ADDRESS1),
            address2: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.ADDRESS2),
            city: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.CITY),
            stateCode: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.STATE_CODE),
            postalCode: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.POSTAL_CODE),
            countryCode: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.COUNTRY_CODE),
            contactPerson: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.CONTACT_PERSON),
            telNo: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.TEL_NO),
            taciOcnA6aShipments: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.TITLE),
            sNo: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.S_NO),
            itemDesc1: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC1),
            itemDesc2: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC2),
            itemDesc3: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC3),
            itemDesc4: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC4),
            itemDesc5: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC5),
            itemDesc6: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC6),
            itemDesc7: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC7),
            itemDesc8: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC8),
            itemDesc9: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.ITEM_DESC9),
            noOfPkgs: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.NO_OF_PKGS),
            pkgUom: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.PKG_UOM),
            grossWeight: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.GROSS_WEIGHT),
            grossweightUom: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.GROSSWEIGHT_UOM),
            volume: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.VOLUME),
            volumeUom: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.VOLUME_UOM),
            hsCode: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.HS_CODE),
            undgCode: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.UNDG_CODE),
            markNo: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.MARK_NO),
            containerNo: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.CONTAINER_NO),
            taciOcnA6aContainers: translate(Namespace.A6A, A6aKeys.TaciOcnA6aContainers.TITLE),
            containerStatus: translate(Namespace.A6A, A6aKeys.TaciOcnA6aContainers.CONTAINER_STATUS),
            containertypeSize: translate(Namespace.A6A, A6aKeys.TaciOcnA6aContainers.CONTAINERTYPE_SIZE),
            carriageCond: translate(Namespace.A6A, A6aKeys.TaciOcnA6aContainers.CARRIAGE_COND),
            sealNo: translate(Namespace.A6A, A6aKeys.TaciOcnA6aContainers.SEAL_NO),
            taciOcnA6aSN: translate(Namespace.A6A, A6aKeys.TaciOcnA6aSN.TITLE),
            email: translate(Namespace.A6A, A6aKeys.TaciOcnA6aSN.EMAIL),
            phno: translate(Namespace.A6A, A6aKeys.TaciOcnA6aSN.PHNO),
            name: translate(Namespace.A6A, A6aKeys.TaciOcnA6aSN.NAME),
            snType: translate(Namespace.A6A, A6aKeys.TaciOcnA6aSN.SNTYPE),
            a8aPrinting: translate(Namespace.A6A, A6aKeys.A8A_A10_PRINTING_PURPOSES_ONLY),

            a6aExports: translate(Namespace.A6A, A6aKeys.A6aExports.TITLE),
            exportTransNo: translate(Namespace.A6A, A6aKeys.A6aExports.EXPORT_TRANS_NO),

            partyDropDown: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.PARTY_DROP_DOWN),
            saveFlag: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.SAVE_FLAG),
            partyIdentification: translate(Namespace.A6A, A6aKeys.TaciOcnA6aParties.PARTY_IDENTIFICATION),

            productDropDown: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.PRODUCT_DROP_DOWN),
            productIdentification: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.PRODUCT_IDENTIFICATION),
            itemNo: translate(Namespace.A6A, A6aKeys.TaciOcnA6aShipments.S_NO),

            conveyancereferencenumber: translate(Namespace.A6A, A6aKeys.CONV_REFNO),
            errorDetails: translate(Namespace.A6A, A6aKeys.ERROR_CODE),

            d4title: translate(Namespace.A6A, A6aKeys.D4_TITLE),
            systemDateTime: translate(Namespace.A6A, A6aKeys.SYSTEM_PROCESSED_DATE_TIME),
            cbsaRefNo: translate(Namespace.A6A, A6aKeys.CBSA_REF_NO),
            cbsaProcessingDate: translate(Namespace.A6A, A6aKeys.CBSA_PROCESSED_DATE_TIME),
            remarks: translate(Namespace.A6A, A6aKeys.REMARKS),
            documentType: translate(Namespace.A6A, A6aKeys.DOCUMENT_TYPE),


            errorCode: translate(Namespace.A6A, A6aKeys.ERRORCODE),
            fieldDesc: translate(Namespace.A6A, A6aKeys.FIELDDESC),
            rejectType: translate(Namespace.A6A, A6aKeys.REJECT_TYPE),
            errorDesc: translate(Namespace.A6A, A6aKeys.ERROR_DESC),
            responseText: translate(Namespace.A6A, A6aKeys.RESPONSE_TEXT)
        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <A6ATranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </A6ATranslationContext.Provider>
    )
}

export default A6ATranslationProvider

export { A6ATranslationContext, A6ATranslationConsumer }

import React, { useRef, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'
import Box from '@material-ui/core/Box'
import TaciOcnA6aContainersFormProperties from './TaciOcnA6aContainersFormProperties'


const {
    button: { CngPrimaryButton },
    form: { CngForm },
    CngDialog
} = components

const { FormState } = constants

function TaciOcnA6aContainersDialog(props) {
    const { open, onClose, container, docFormat, showNotification } = props
    const { getValues, setValue, formState: { isSubmitting } } = useFormContext()
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const lastIndex = useRef(0)

    const { initialValues, makeValidationSchema } = TaciOcnA6aContainersFormProperties.formikProps

    function handleAddContainer(data) {
        const containers = [...getValues('taciOcnA6aContainers'), data].map(
            (container) => ({ ...container, _id: ++lastIndex.current })
        )

        setValue('taciOcnA6aContainers', containers)
        onClose()
    }

    function handleEditContainer(data) {
        const containers = [...getValues('taciOcnA6aContainers')]
        const index = containers.findIndex((container) =>
            container.id ? container.id === data.id : container._id === data._id
        )

        containers[index] = data

        setValue('taciOcnA6aContainers', containers)
        onClose()
    }

    return (
        <CngDialog
            dialogContent={
                <CngForm
                    fieldLevel='form'
                    formikProps={{
                        initialValues: container || initialValues,
                        makeValidationSchema: makeValidationSchema,
                        onSubmit: (data) => {
                            if (container) {
                                handleEditContainer(data)
                            } else {
                                handleAddContainer(data)
                            }
                        }
                    }}
                    formState={FormState.COMPLETED}
                    innerForm={true}
                    renderBodySection={(labelMap, shouldHideMap) => (
                        <TaciOcnA6aContainersFormProperties.Fields showNotification={showNotification} shouldHideMap={shouldHideMap} docFormat={docFormat} />
                    )}
                    renderButtonSection={() => (
                        <Box display='flex' justifyContent='flex-end'>
                            <CngPrimaryButton type='submit' disabled={isSubmitting}>
                                {container ? "Update" : "Save"}
                            </CngPrimaryButton>
                        </Box>
                    )}
                />
            }
            dialogTitle={getTranslatedText('A6A', 'taciOcnA6aContainers')}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            fullWidth
            maxWidth='lg'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default TaciOcnA6aContainersDialog

import React, { useContext } from 'react'
import StatusBar from 'src/components/aciacehighway/StatusBar'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import TaciOcnA6aContainersTable from './TaciOcnA6aContainersTable'
import TaciOcnA6aShipmentsTable from './TaciOcnA6aShipmentsTable'
import TaciOcnA6aSNTable from './TaciOcnA6aSNTable'
import A6aResponse from './A6aResponse'
import AuditLog from './AuditLog'
import moment from 'moment-timezone'
import { Box, Card, Grid, Typography, useTheme } from '@material-ui/core'
import { A6ATranslationContext } from './contexts/A6ATranslationContext'

function A6aViewPage(props) {
  const { id, data, getCountryStateLabel, getLookupValue } = props
  const { getTranslatedText } = useContext(A6ATranslationContext)

  const theme = useTheme()

  function shouldShowScheduleSection() {
    return data.docFormat === "83" || data.docFormat === "711"
      || data.docFormat === "695" || data.docFormat === "687"
  }

  function shouldShowContainersSection() {
    return data.bulkShipment === "N" || data.docFormat === "695"
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatusBar status={data.a6aStatus} />
      </Grid>

      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6A', 'header')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={getTranslatedText('A6A', 'docFormat')}>
                {getLookupValue('docType', data.docFormat)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={getTranslatedText('A6A', 'carrierCode')}>
                {data.carrierCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={getTranslatedText('A6A', 'uniqueConsignmentRefNo')}>
                {data.uniqueConsignmentRefNo}
              </CngField>
            </Grid>

            <Grid item xs={12} sm={6} md={3} shouldHide={data.docFormat === "687"}>
              <CngField label={getTranslatedText('A6A', 'ccn')}>
                {data.ccn}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} shouldHide={data.docFormat !== "687"}>
              <CngField label="Supplementary Reference Number">
                {data.ccn}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} shouldHide={data.docFormat !== "687"}>
              <CngField label={getTranslatedText('A6A', 'originalCcn')}>
                {data.originalCcn}
              </CngField>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <CngField label={getTranslatedText('A6A', 'mot')}>
                {getLookupValue('mot', data.mot)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={getTranslatedText('A6A', 'bolNo')}>
                {data.bolNo}
              </CngField>
            </Grid>
            <Grid item xs={12}>
              <CngField label={getTranslatedText('A6A', 'customsProcedure')}>
                {getLookupValue('customsProc', data.customsProcedure)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={getTranslatedText('A6A', 'specialIns')}>
                {data.specialIns}
              </CngField>
            </Grid>

            <Grid item xs={12} sm={6} md={4} shouldHide={(data.docFormat !== "83")}>
              <CngField label={getTranslatedText('A6A', 'supplementaryReqInd')}>
                {getLookupValue('supReq', data.supplementaryReqInd)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} shouldHide={data.docFormat === "695"}>
              <CngField label={getTranslatedText('A6A', 'bulkShipment')}>
                {data.bulkShipment}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>

      {shouldShowScheduleSection() &&
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('A6A', 'schedule')}>
            {(data.docFormat === "83") && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <CngField label={getTranslatedText('A6A', 'a6ConvRefNo')}>
                    {data.a6ConvRefNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CngField label={getTranslatedText('A6A', 'vesselName')}>
                    {data.vesselName}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'voyageNo')}>
                    {data.voyageNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'portOfLoading')}>
                    {data.portOfLoading}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'estimatedLoadingDate')}>
                    {data.estimatedLoadingDate
                      ? moment(data.estimatedLoadingDate).format('YYYY-MM-DD HH:mm:ss')
                      : data.estimatedLoadingDate}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'portOfArrival')}>
                    {data.portOfArrival}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'arrivalTerminal')}>
                    {data.arrivalTerminal}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'pierOfArrival')}>
                    {data.pierOfArrival}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'customsOffOfPortArrival')}>
                    {getLookupValue('customsOffice', data.customsOffOfPortArrival)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'customsOffOfPortArrivalSubLocCode')}>
                    {getLookupValue('subLoc', data.customsOffOfPortArrivalSubLocCode)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'foreignPortOfLading')}>
                    {getLookupValue('countries', data.foreignPortOfLading)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoLoadingCity')}>
                    {data.cargoLoadingCity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoLoadingPort')}>
                    {data.cargoLoadingPort}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCountry')}>
                    {getLookupValue('countries', data.cargoDestinationCountry)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCity')}>
                    {data.cargoDestinationCity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationPort')}>
                    {data.cargoDestinationPort}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'customsOffOfManifestDestination')}>
                    {getLookupValue('customsOffice', data.customsOffOfManifestDestination)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'customsOffOfManifestDestinationSubLocCode')}>
                    {getLookupValue('subLoc', data.customsOffOfManifestDestinationSubLocCode)}
                  </CngField>
                </Grid>
              </Grid>
            )}

            {(data.docFormat === "711") && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'a6ConvRefNo')}>
                    {data.a6ConvRefNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CngField label={getTranslatedText('A6A', 'vesselName')}>
                    {data.vesselName}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'voyageNo')}>
                    {data.voyageNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'portOfLoading')}>
                    {data.portOfLoading}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'terminalOfLoading')}>
                    {data.terminalOfLoading}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'pierOfLoading')}>
                    {data.pierOfLoading}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'vesselDepartureDate')}>
                    {data.vesselDepartureDate
                      ? moment(data.vesselDepartureDate).format('YYYY-MM-DD HH:mm:ss')
                      : data.vesselDepartureDate}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'customsOfficeOfExit')}>
                    {getLookupValue('customsOffice', data.customsOfficeOfExit)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'foreignPortOfLading')}>
                    {getLookupValue('countries', data.foreignPortOfLading)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoLoadingCity')}>
                    {data.cargoLoadingCity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoLoadingPort')}>
                    {data.cargoLoadingPort}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCountry')}>
                    {getLookupValue('countries', data.cargoDestinationCountry)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCity')}>
                    {data.cargoDestinationCity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationPort')}>
                    {data.cargoDestinationPort}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'portOfDischarge')}>
                    {data.portOfDischarge}
                  </CngField>
                </Grid>
              </Grid>
            )}

            {(data.docFormat === "695") && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'a6ConvRefNo')}>
                    {data.a6ConvRefNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'vesselName')}>
                    {data.vesselName}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'voyageNo')}>
                    {data.voyageNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'portOfLoading')}>
                    {data.portOfLoading}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'portOfArrival')}>
                    {data.portOfArrival}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'arrivalTerminal')}>
                    {data.arrivalTerminal}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'pierOfArrival')}>
                    {data.pierOfArrival}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'customsOffOfPortArrival')}>
                    {getLookupValue('customsOffice', data.customsOffOfPortArrival)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'foreignPortOfLading')}>
                    {getLookupValue('countries', data.foreignPortOfLading)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoLoadingCity')}>
                    {data.cargoLoadingCity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoLoadingPort')}>
                    {data.cargoLoadingPort}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCountry')}>
                    {getLookupValue('countries', data.cargoDestinationCountry)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCity')}>
                    {data.cargoDestinationCity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationPort')}>
                    {data.cargoDestinationPort}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'customsOffOfManifestDestination')}>
                    {getLookupValue('customsOffice', data.customsOffOfManifestDestination)}
                  </CngField>
                </Grid>
              </Grid>
            )}

            {(data.docFormat === "687") && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCountry')}>
                    {getLookupValue('countries', data.cargoDestinationCountry)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationCity')}>
                    {data.cargoDestinationCity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <CngField label={getTranslatedText('A6A', 'cargoDestinationPort')}>
                    {data.cargoDestinationPort}
                  </CngField>
                </Grid>
              </Grid>
            )}
          </CngSection>
        </Grid>
      }

      {data.docFormat === "711" && <>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('A6A', 'a6aExports')}>
            {data.a6aExports.length > 0 ? (
              <Grid container spacing={1}>
                {data.a6aExports.map((exports, index) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('A6A', 'exportTransNo') + ' ' + (index + 1)}>
                      {exports.exportTransNo}
                    </CngField>
                  </Grid>
                ))}

              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSection>
        </Grid>

        <Grid item xs={12}>
          <CngSection title={getTranslatedText('A6A', 'a6aPrevious')}>
            {data.a6aPrevious.length > 0 ? (
              <Grid container spacing={1}>
                {data.a6aPrevious.map((ccn, index) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={getTranslatedText('A6A', 'previousCcn') + ' ' + (index + 1)}>
                      {ccn.previousCcn}
                    </CngField>
                  </Grid>
                ))}

              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSection>
        </Grid>
      </>}

      <Grid item xs={12}>
        <CngSection title={getTranslatedText('A6A', 'taciOcnA6aParties')}>
          {data.taciOcnA6aParties.length > 0 ? (
            <Grid container spacing={1}>
              {data.taciOcnA6aParties.map(party => (
                <Grid key={party.id || party._id} item xs={12}>
                  <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'partyType')}>
                            {getLookupValue('partyType', party.partyType)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'partyName1')}>
                            {party.partyName1}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'partyName2')}>
                            {party.partyName2}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'address1')}>
                            {party.address1}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'address2')}>
                            {party.address2}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'city')}>
                            {party.city}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'postalCode')}>
                            {party.postalCode}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'countryCode')}>
                            {getLookupValue('countries', party.countryCode)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'stateCode')}>
                            {getCountryStateLabel(party.countryCode, party.stateCode)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'contactPerson')}>
                            {party.contactPerson}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                          <CngField label={getTranslatedText('A6A', 'telNo')}>
                            {party.telNo}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>

      {shouldShowContainersSection() &&
        <Grid item xs={12}>
          <CngSection title={
            <Typography variant='h5' style={{ fontWeight: 700 }}>
              {getTranslatedText('A6A', 'taciOcnA6aContainers') + ' '}
              <Typography color='textSecondary' component='span' variant='caption'>
                {`/ ${data.taciOcnA6aContainers.length} Entries`}
              </Typography>
            </Typography>
          }>
            <TaciOcnA6aContainersTable data={data.taciOcnA6aContainers} />
          </CngSection>
        </Grid>
      }

      {data.docFormat !== "695" &&
        <Grid item xs={12}>
          <CngSection title={
            <Typography variant='h5' style={{ fontWeight: 700 }}>
              {getTranslatedText('A6A', 'taciOcnA6aShipments') + ' '}
              <Typography color='textSecondary' component='span' variant='caption'>
                {`/ ${data.taciOcnA6aShipments.length} Entries`}
              </Typography>
            </Typography>
          }>
            <TaciOcnA6aShipmentsTable data={data.taciOcnA6aShipments} />
          </CngSection>
        </Grid>
      }

      <Grid item xs={12}>
        <CngSection title={
          <Typography variant='h5' style={{ fontWeight: 700 }}>
            {getTranslatedText('A6A', 'taciOcnA6aSN') + ' '}
            <Typography color='textSecondary' component='span' variant='caption'>
              {`/ ${data.taciOcnA6aSN.length} Entries`}
            </Typography>
          </Typography>
        }>
          <TaciOcnA6aSNTable data={data.taciOcnA6aSN} />
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <A6aResponse a6aId={id} ccnValue={data.ccn} />
      </Grid>
      <Grid item xs={12}>
        <AuditLog a6aId={id} />
      </Grid>
    </Grid>
  )
}

export default A6aViewPage

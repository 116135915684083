import React, { useContext} from 'react'
import { Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { A6ATranslationContext } from '../contexts/A6ATranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
    form: {
        field: {
            CngTextField,
            CngCodeMasterAutocompleteField
        }
    },
    CngGridItem
} = components

function A6AHeaderSection(props) {
    const { disabled, shouldHideMap } = props
    const { setValue} = useFormContext();
    const { getTranslatedText } = useContext(A6ATranslationContext)

    const docFormat = useWatch({ name: "docFormat" })

    function setvaluesOnProc(event) {
        if (event !== "" && event !== null) {
            setValue("taciOcnA6aContainers", [])
            setValue("taciOcnA6aShipments", [])
            setValue("a6aPrevious", [])
            setValue("a6aExports", [])
            setValue("taciOcnA6aParties", [])
            setValue("taciOcnA6aSN", [])
            setValue("carrierCode", "")
            setValue("ccn", "")
            setValue("uniqueConsignmentRefNo", "")
            setValue("originalCcn", "")
            setValue("specialIns", "")
            setValue("bulkShipment", "")
            setValue("supplementaryReqInd", "")
            setValue("mot", "")
            setValue("bolNo", "")
            setValue("customsProcedure", "")
            setValue("a6ConvRefNo", "")
            setValue("vesselName", "")
            setValue("voyageNo", "")
            setValue("portOfLoading", "")
            setValue("terminalOfLoading", "")
            setValue("pierOfLoading", "")
            setValue("estimatedLoadingDate", "")
            setValue("vesselDepartureDate", "")
            setValue("customsOfficeOfExit", "")
            setValue("portOfArrival", "")
            setValue("pierOfArrival", "")
            setValue("foreignPortOfLading", "")
            setValue("cargoLoadingCity", "")
            setValue("cargoLoadingPort", "")
            setValue("customsOffOfPortArrival", "")
            setValue("customsOffOfPortArrivalSubLocCode", "")
            setValue("customsOffOfManifestDestination", "")
            setValue("customsOffOfManifestDestinationSubLocCode", "")
            setValue("cargoDestinationCountry", "")
            setValue("cargoDestinationCity", "")
            setValue("cargoDestinationPort", "")
            setValue("customsOffOfDischarge", "")
            setValue("arrivalTerminal", "")
            setValue("carrierName", "")
            setValue("terminalName", "")
            setValue("portOfDischarge", "")
            setValue("templateFlag", false)
            setValue("templateName", "")
        }
    }

    function setContainerValues(event) {
        if (event !== "" && event !== null) {
            setValue("taciOcnA6aContainers", [])
        }
    }
    
    function setvaluesOnCustomProcedureChange(event) {
        if (event == "26") {
            setValue("customsOffOfPortArrival", "")
            setValue("customsOffOfPortArrivalSubLocCode", "")
            setValue("customsOffOfManifestDestination", "")
            setValue("customsOffOfManifestDestinationSubLocCode", "")
        }
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6A', 'header')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.docFormat}>
                        <CngCodeMasterAutocompleteField
                            name="docFormat"
                            label={getTranslatedText('A6A', 'docFormat')}
                            disabled={disabled}
                            codeType='A6A_DOC_TYPE'
                            onChange={setvaluesOnProc}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCode}>
                        <CngTextField
                            name="carrierCode"
                            inputProps={{ maxLength: 4 }}
                            label={getTranslatedText('A6A', 'carrierCode')}
                            disabled={disabled}
                            required
                            size='small'
                            onBlur={(e) => { setValue('carrierCode', e.target.value.toUpperCase()) }}

                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.uniqueConsignmentRefNo}>
                        <CngTextField
                            name="uniqueConsignmentRefNo"
                            label={getTranslatedText('A6A', 'uniqueConsignmentRefNo')}
                            disabled={disabled}
                            inputProps={{ maxLength: 35 }}
                            size='small'
                            onBlur={(e) => { setValue('uniqueConsignmentRefNo', e.target.value.toUpperCase()) }}

                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat === "687"}>
                        <CngTextField
                            name="ccn"
                            label={getTranslatedText('A6A', 'ccn')}
                            disabled={disabled}
                            inputProps={{ maxLength: 25 }}
                            required
                            size='small'
                            onBlur={(e) => { setValue('ccn', e.target.value.toUpperCase()) }}

                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat !== "687"}>
                        <CngTextField
                            name="ccn"
                            label={"Supplementary Reference Number"}
                            disabled={disabled}
                            inputProps={{ maxLength: 25 }}
                            required
                            size='small'
                            onBlur={(e) => { setValue('ccn', e.target.value.toUpperCase()) }}

                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat !== "687"}>
                        <CngTextField
                            name="originalCcn"
                            label={getTranslatedText('A6A', 'originalCcn')}
                            disabled={disabled}
                            inputProps={{ maxLength: 25 }}
                            required
                            size='small'
                            onBlur={(e) => { setValue('originalCcn', e.target.value.toUpperCase()) }}    
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mot}>
                        <CngCodeMasterAutocompleteField
                            name="mot"
                            label={getTranslatedText('A6A', 'mot')}
                            disabled={disabled}
                            codeType='A6A_MODE_OF_TRANSPORT'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat !== "83"}>
                        <CngCodeMasterAutocompleteField
                            name="customsProcedure"
                            label={getTranslatedText('A6A', 'customsProcedure')}
                            onChange={setvaluesOnCustomProcedureChange}
                            disabled={disabled}
                            codeType='A6A_CUSTOMS_PROCEDURE_IMPORT'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat !== "711"}>
                        <CngCodeMasterAutocompleteField
                            name="customsProcedure"
                            label={getTranslatedText('A6A', 'customsProcedure')}
                            onChange={setvaluesOnCustomProcedureChange}
                            disabled={disabled}
                            codeType='A6A_CUSTOMS_PROCEDURE_EXPORT'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat !== "687"}>
                        <CngCodeMasterAutocompleteField
                            name="customsProcedure"
                            label={getTranslatedText('A6A', 'customsProcedure')}
                            onChange={setvaluesOnCustomProcedureChange}
                            disabled={disabled}
                            codeType='A6A_CUSTOMS_PROCEDURE_SUPP'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat !== "695"}>
                        <CngCodeMasterAutocompleteField
                            name="customsProcedure"
                            label={getTranslatedText('A6A', 'customsProcedure')}
                            onChange={setvaluesOnCustomProcedureChange}
                            disabled={disabled}
                            codeType='A6A_CUSTOMS_PROCEDURE_EMPTY'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bolNo}>
                        <CngTextField
                            name="bolNo"
                            label={getTranslatedText('A6A', 'bolNo')}
                            disabled={disabled}
                            inputProps={{ maxLength: 35 }}
                            required
                            size='small'
                            onBlur={(e) => { setValue('bolNo', e.target.value.toUpperCase()) }}    
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.specialIns}>
                        <CngTextField
                            name="specialIns"
                            label={getTranslatedText('A6A', 'specialIns')}
                            disabled={disabled}
                            inputProps={{ maxLength: 60 }}
                            size='small'
                            onBlur={(e) => { setValue('specialIns', e.target.value.toUpperCase()) }}

                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={(docFormat !== "83")}>
                        <CngCodeMasterAutocompleteField
                            name="supplementaryReqInd"
                            label={getTranslatedText('A6A', 'supplementaryReqInd')}
                            disabled={disabled}
                            codeType='A6A_SUPP_REQ_IND'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={docFormat === "695"}>
                        <CngCodeMasterAutocompleteField
                            name="bulkShipment"
                            label={getTranslatedText('A6A', 'bulkShipment')}
                            disabled={disabled}
                            codeType='A6A_BULK_SHIPMENT'
                            onChange={setContainerValues}
                            required
                            size='small'
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default A6AHeaderSection
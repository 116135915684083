import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  
  return Yup.object({
   // sNo: Yup.number(),
    saveFlag: Yup.boolean().nullable(),
    productIdentification: Yup.string().when('saveFlag',{
     is: false,
     then: schema => schema,
     otherwise: Yup.string().when('saveFlag', {
       is: true,
       then: Yup.string().min(1, "Max length allowed is 1.").max(35, "Max length is 35.").required(requiredMessage).matches("[a-zA-Z0-9\s]$", "Only alphanumeric characters are allowed."),
     })
     }).nullable(),
    itemNo: Yup.string().trim().max(4, "Max length allowed is 4").required(requiredMessage).matches(/^\d+$/, "Only numbers are allowed!").nullable(),
    itemDesc1: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc2: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc3: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc4: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc5: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc6: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc7: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc8: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    itemDesc9: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/, "Cannot contain special characters like )(@*:etc.").max(50,"Max length allowed is 50").nullable(),
    noOfPkgs: Yup.string().required(requiredMessage).matches(/^[0-9-. ]+$/,"Can Contain only numeric values"),
    pkgUom: Yup.string().required(requiredMessage).nullable(),
    grossWeight: Yup.string().required(requiredMessage).matches(/^[0-9-. ]+$/,"Can Contain only numeric values)"),
    grossWeight: Yup.string().trim().max(16, "Max length allowed is 16").required(requiredMessage).matches(/^\d+(\.\d{0,4})?$/, "Only 4 decimals places are allowed."),
    grossweightUom: Yup.string().required(requiredMessage).nullable(),
    volume: Yup.string().matches(/^[0-9-. ]*$/,"Can Contain only numeric values").nullable(),
    volumeUom: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    hsCode: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    undgCode: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").nullable(),
    markNo: Yup.string().nullable().max(50,"Max length allowed is 50"),
    containerNo: Yup.lazy((value) => value === '' ? Yup.string() : Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like )(@*:etc.").max(11,"Container length should be of 11").min(11,"Container length should be of 11").nullable()),
  })
}

export default makeValidationSchema
